import { MONITOORA_ROLES } from '@/modules/monitoora/constants'

export function ptRoleToCode(ptRole: string): string {
  const roles: Record<string, string> = {
    aluno: 'STUDENT',
    responsavel: 'RESPONSIBLE',
    professor: 'TEACHER',
    edtech: 'EDTECH',
    instituicao: 'INSTITUTION',
    te: 'TE',
    diretor: 'DIRECTOR',
    coordenador: 'COORDINATOR',
    administrador: 'ADMINISTRATOR',
    colaborador: 'CONTRIBUTOR',
    ...MONITOORA_ROLES.reduce(
      (prevRoles, role) => ({ ...prevRoles, [role]: role.toUpperCase() }),
      {}
    ),
    billing: 'BILLING',
    INTERNAL_ADMINISTRATOR: 'INTERNAL_ADMINISTRATOR',
    INTERNAL_SUPPORT: 'INTERNAL_SUPPORT'
  }
  return roles[ptRole]
}

export function decodeTokenPayload<TokenPayload>(token: string): TokenPayload {
  const [, encodedTokenPayload] = token.split('.')
  const decodedTokenPayload = atob(encodedTokenPayload)
  return JSON.parse(decodedTokenPayload) as TokenPayload
}
