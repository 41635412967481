import { BaseCard } from '@positivote/design-system/components/BaseCard'
import { Chip } from '@positivote/design-system/components/Chip'
import { Div } from '@positivote/design-system/components/Div'
import { Grid } from '@positivote/design-system/components/Grid'
import { IconWrapper } from '@positivote/design-system/components/IconWrapper'
import { Image } from '@positivote/design-system/components/Image'
import { LI } from '@positivote/design-system/components/LI'
import { Loader } from '@positivote/design-system/components/Loader'
import { Main } from '@positivote/design-system/components/Main'
import { Pagination } from '@positivote/design-system/components/Pagination'
import { Select } from '@positivote/design-system/components/Select'
import { Switch } from '@positivote/design-system/components/Switch'
import { TextField, TextFieldRef } from '@positivote/design-system/components/TextField'
import { Tooltip } from '@positivote/design-system/components/Tooltip'
import { Typography } from '@positivote/design-system/components/Typography'
import { UL } from '@positivote/design-system/components/UL'
import { CloseIcon } from '@positivote/design-system/icons/Close'
import { FilterListIcon } from '@positivote/design-system/icons/FilterList'
import { PersonIcon } from '@positivote/design-system/icons/Person'
import { SearchIcon } from '@positivote/design-system/icons/Search'
import { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { AppBar } from '@/common/components/AppBar'
import { EmptyList } from '@/common/components/EmptyList'
import { EmptySearch } from '@/common/components/EmptySearch'
import { changePageTitle, debounceEvent } from '@/common/helpers'
import { i18n } from '@/common/i18n'
import { useAuth } from '@/modules/hub/auth/contexts'
import { ListProfileHookParams } from '@/modules/hub/profiles/contracts'
import {
  useListMyProfile,
  useListProfileRoles,
  useUpdateProfileConsent
} from '@/modules/hub/profiles/hooks'

export function MyUsersList(): JSX.Element {
  changePageTitle(i18n().modules.hub.users.pages.list.pageTitle)

  const { profile } = useAuth()

  const searchTextFieldRef = useRef<TextFieldRef>(null)
  const [showFilter, setShowFilter] = useState(false)
  const [loadingConsent, setLoadingConsent] = useState<Record<string, boolean>>({})
  const [listProfileParams, setListProfileParams] = useState<ListProfileHookParams['model']>({
    page: 1,
    perPage: 25,
    orgId: profile?.organizationId,
    byAccount: false
  })

  const navigate = useNavigate()
  const updateProfileConsent = useUpdateProfileConsent()
  const listProfileRoles = useListProfileRoles({})
  const listProfile = useListMyProfile({ model: listProfileParams })

  function handleChangeSearchText(event: React.ChangeEvent<HTMLInputElement>): void {
    const search = event.target.value || undefined
    debounceEvent(() => {
      setListProfileParams((oldState) => ({ ...oldState, search, page: 1 }))
    })()
  }

  function clearSearchText(): void {
    setListProfileParams((oldState) => ({ ...oldState, search: undefined, page: 1 }))
    searchTextFieldRef.current?.clearInput()
  }

  function handleImageConsent(profileId: number, consent: boolean): void {
    setLoadingConsent((oldState) => ({
      ...oldState,
      [profileId]: true
    }))
    updateProfileConsent.mutate({
      model: {
        ...listProfileParams,
        profileId,
        consent: !consent
      },
      onSuccess: () => {
        setLoadingConsent((oldState) => ({
          ...oldState,
          [profileId]: false
        }))
      },
      onError: () => {
        setLoadingConsent((oldState) => ({
          ...oldState,
          [profileId]: false
        }))
      }
    })
  }

  useEffect(() => {
    if (listProfile.data?.registers.length && !showFilter) {
      setShowFilter(true)
    }
  }, [listProfile.data?.registers.length, showFilter])

  const breadcrumbItems = [
    {
      label: i18n().modules.hub.users.pages.list.appBar.breadcrumbs.overview,
      onClick: () => navigate(-1)
    },
    {
      label: i18n().modules.hub.users.pages.list.appBar.breadcrumbs.title
    }
  ]

  return (
    <Main css={{ display: 'flex', flexDirection: 'column', flex: 1, overflowX: 'hidden' }}>
      <AppBar
        title={i18n().modules.hub.users.pages.list.appBar.title}
        goBackFunction={() => navigate(-1)}
        breadcrumbItems={breadcrumbItems}
      />
      <Div
        css={{
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
          padding: '$lg',
          overflowY: 'auto',
          '@sm': { padding: '$md' }
        }}
      >
        {showFilter && (
          <>
            <Div css={{ display: 'flex', alignItems: 'center', gap: '$2xs', marginBottom: '$md' }}>
              <FilterListIcon size={18} />
              <Typography
                variant="titleMedium"
                data-testid="Typography-titleFilter"
                css={{ color: '$on-surface' }}
              >
                {i18n().modules.hub.users.pages.list.filter.title}
              </Typography>
            </Div>
            <Div css={{ display: 'flex', gap: '$lg', marginBottom: '$lg' }}>
              <Grid xl={3}>
                <Select
                  label={i18n().modules.hub.users.pages.list.filter.select}
                  hasNoneOption
                  data-testid="roleCode"
                  optionKeyField="code"
                  optionTitleField="name"
                  options={listProfileRoles.data?.registers ?? []}
                  onChange={(role) =>
                    setListProfileParams((oldState) => ({
                      ...oldState,
                      roleCode: role?.code,
                      page: 1
                    }))
                  }
                  variant="outlined"
                />
              </Grid>
              <Grid xl={9}>
                <TextField
                  ref={searchTextFieldRef}
                  label={i18n().modules.hub.users.pages.list.filter.searchLabel}
                  variant="outlined"
                  data-testid="search"
                  inputProps={{ onChange: handleChangeSearchText }}
                  css={{ marginBottom: '$md' }}
                  leadingIcon={{ icon: SearchIcon }}
                  trailingIcon={
                    listProfileParams.search
                      ? { icon: CloseIcon, onClick: clearSearchText }
                      : undefined
                  }
                />
              </Grid>
            </Div>
            {listProfile.isFetching && listProfileParams.search && (
              <Typography
                variant="titleLarge"
                css={{ color: '$on-surface', marginBottom: '$lg' }}
                data-testid="Typography-searching"
              >
                {i18n().modules.hub.users.pages.list.filter.searching}
              </Typography>
            )}
            {!listProfile.isFetching &&
              !!listProfile.data?.registers.length &&
              listProfileParams.search && (
                <Typography
                  variant="titleLarge"
                  css={{ color: '$on-surface', marginBottom: '$lg' }}
                  data-testid="Typography-searchingResult"
                >
                  {i18n().modules.hub.users.pages.list.filter.searchingResult}
                </Typography>
              )}
          </>
        )}

        {!listProfile.data?.registers.length ? (
          <Div
            css={{
              display: 'flex',
              flex: 1,
              alignItems: 'center',
              justifyContent: 'center',
              position: 'relative'
            }}
          >
            {listProfile.isFetching && (
              <Loader data-testid="Loader-Container-MyUsersList" size={80} />
            )}
            {!listProfile.isFetching && !listProfileParams.search && (
              <EmptyList title={i18n().modules.hub.users.pages.list.emptyList} />
            )}
            {!listProfile.isFetching && listProfileParams.search && <EmptySearch />}
          </Div>
        ) : (
          <>
            <Grid spacing="$md" css={{ padding: '$md $lg', marginBottom: '$2xs' }}>
              <Grid xl={4} lg={3}>
                <Typography
                  variant="titleMedium"
                  lineClamp={1}
                  css={{ color: '$on-surface' }}
                  data-testid="Typography-headerUsername"
                >
                  {i18n().modules.hub.users.pages.list.header.username}
                </Typography>
              </Grid>
              <Grid xl={2}>
                <Typography
                  variant="titleMedium"
                  lineClamp={1}
                  css={{ color: '$on-surface' }}
                  data-testid="Typography-headerEnrollment"
                >
                  {i18n().modules.hub.users.pages.list.header.enrollment}
                </Typography>
              </Grid>
              <Grid xl={2}>
                <Typography
                  variant="titleMedium"
                  lineClamp={1}
                  css={{ color: '$on-surface' }}
                  data-testid="Typography-headerEmail"
                >
                  {i18n().modules.hub.users.pages.list.header.email}
                </Typography>
              </Grid>
              <Grid xl={2} lg={3}>
                <Tooltip
                  placement="bottom-start"
                  label={i18n().modules.hub.users.pages.list.header.imageUsageTerm}
                >
                  <Typography
                    variant="titleMedium"
                    lineClamp={1}
                    css={{ color: '$on-surface' }}
                    data-testid="Typography-headerImageUsageTerm"
                  >
                    {i18n().modules.hub.users.pages.list.header.imageUsageTerm}
                  </Typography>
                </Tooltip>
              </Grid>
              <Grid xl={2}>
                <Typography
                  variant="titleMedium"
                  lineClamp={1}
                  css={{ color: '$on-surface' }}
                  data-testid="Typography-headerProfile"
                >
                  {i18n().modules.hub.users.pages.list.header.profile}
                </Typography>
              </Grid>
            </Grid>
            <UL
              css={{
                ...(listProfile.isFetching && {
                  ...(listProfile.data.lastPage <= 1 && { flex: 1 }),
                  maxHeight: '50vh',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  position: 'relative'
                })
              }}
            >
              {listProfile.isFetching && (
                <Loader data-testid="Loader-Container-MyUsersList" size={80} />
              )}
              {listProfile.data.registers.map((register, index) => (
                <BaseCard
                  key={register.id}
                  data-testid={`BaseCard-Container-contractListItem-${index}`}
                  component={LI}
                  css={{
                    opacity: listProfile.isFetching ? '$transparent' : '$default',
                    marginBottom: '$2xs',
                    '&:last-child': { marginBottom: '$none' },
                    '& .BaseCard-StateLayer': { height: 'min-content', padding: '$md $lg' }
                  }}
                >
                  <Grid spacing="$md" css={{ display: 'flex', alignItems: 'center' }}>
                    <Grid xl={4} lg={3} css={{ display: 'flex', alignItems: 'center', gap: '$md' }}>
                      <Image
                        alt={`${register.name} picture`}
                        src={register.picture}
                        FallbackImage={() => (
                          <IconWrapper
                            data-testid="UsersList-FallbackImage"
                            size="$2xl"
                            css={{ backgroundColor: '$primary-container' }}
                          >
                            <PersonIcon fill="$on-primary-container" />
                          </IconWrapper>
                        )}
                        css={{
                          height: '$2xl',
                          width: '$2xl',
                          borderRadius: '$full',
                          objectFit: 'cover'
                        }}
                      />
                      <Tooltip placement="bottom-start" label={register.name}>
                        <Typography
                          variant="bodyMedium"
                          lineClamp={1}
                          css={{ color: '$on-surface' }}
                          data-testid={`Typography-listItemUsername-${index}`}
                        >
                          {register.name}
                        </Typography>
                      </Tooltip>
                    </Grid>
                    <Grid xl={2} lg={2}>
                      <Typography
                        variant="bodyMedium"
                        lineClamp={1}
                        css={{ color: '$on-surface' }}
                        data-testid={`Typography-listItemEnrollment-${index}`}
                      >
                        {register.code}
                      </Typography>
                    </Grid>
                    <Grid xl={2} lg={2}>
                      {register.email ? (
                        <Tooltip placement="bottom-start" label={register.email}>
                          <Typography
                            variant="bodyMedium"
                            lineClamp={1}
                            css={{
                              color: '$on-surface'
                            }}
                            data-testid={`Typography-listItemEmail-${index}`}
                          >
                            {register.email}
                          </Typography>
                        </Tooltip>
                      ) : (
                        <Typography
                          variant="bodyMedium"
                          lineClamp={1}
                          css={{
                            color: '$on-surface'
                          }}
                        >
                          -
                        </Typography>
                      )}
                    </Grid>
                    <Grid
                      xl={2}
                      lg={3}
                      css={{ display: 'flex', gap: '$2xs', alignItems: 'center' }}
                    >
                      <Switch
                        isLoading={!!loadingConsent[register.id]}
                        data-testid={`Switch-Container-${register.id}`}
                        inputProps={{
                          checked: register.allowedImageUseOnEdtech,
                          'data-testid': `Switch-Input-${register.id}`,
                          onChange: () =>
                            handleImageConsent(register.id, register.allowedImageUseOnEdtech)
                        }}
                        css={{ padding: '$2xs' }}
                      />
                      <Tooltip
                        placement="bottom-start"
                        label={i18n().modules.hub.users.pages.list.body.switchLabel(
                          register.allowedImageUseOnEdtech
                        )}
                      >
                        <Typography
                          variant="bodyMedium"
                          lineClamp={1}
                          css={{ color: '$on-surface' }}
                          data-testid={`Typography-listItemImageUsageTerm-${index}`}
                        >
                          {i18n().modules.hub.users.pages.list.body.switchLabel(
                            register.allowedImageUseOnEdtech
                          )}
                        </Typography>
                      </Tooltip>
                    </Grid>
                    <Grid xl={2} lg={2}>
                      <Chip
                        label={register.role.name}
                        color="neutral"
                        data-testid={`listItemProfile-${index}`}
                      />
                    </Grid>
                  </Grid>
                </BaseCard>
              ))}
            </UL>
            {listProfile.data.lastPage > 1 && (
              <Pagination
                lastPage={listProfile.data.lastPage}
                page={listProfileParams.page ?? 1}
                setPage={(page) => setListProfileParams((oldState) => ({ ...oldState, page }))}
                css={{ marginTop: '$lg' }}
              />
            )}
          </>
        )}
      </Div>
    </Main>
  )
}
