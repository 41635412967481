import { i18n } from '@/common/i18n'
import { SchoolClass } from '@/modules/hub/school-classes/contracts'

export const quickAcessPt = {
  resources: {
    create: 'Criar acesso rápido',
    show: 'Exibir acesso rápido',
    revoke: 'Revogar acesso rápido'
  },
  pages: {
    schoolClassList: {
      pageTitle: 'Acesso rápido',
      appBar: {
        title: 'Acesso rápido'
      }
    },
    form: {
      pageTitle: 'Gerar acesso rápido',
      appBar: {
        title: 'Acesso rápido',
        breadcrumbs: {
          overview: 'Visão geral',
          class: (schoolClass: SchoolClass | undefined): string =>
            schoolClass
              ? `${schoolClass.name} - ${schoolClass.code} - ${
                  i18n().modules.hub.schoolClasses.fullClassLevel[schoolClass.level.code]
                }`
              : '...'
        }
      },
      emptyList: 'Ops! Ainda não temos alunos para exibir',
      classStudents: 'Alunos da turma',
      accessUser: 'Usuário de acesso',
      visibilityOff: '***********',
      online: 'On-line',
      accessCode: 'Código de acesso',
      timeLeft: 'Tempo restante:',
      cancelCode: 'Cancelar código',
      generateCode: 'Gerar código',
      minutes: 'minutos',
      input: {
        duration: 'Duração',
        selectOptionsClassLevel: {
          oneHours: '1 hora',
          twoHours: '2 horas'
        }
      }
    }
  }
}
