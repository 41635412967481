import { UseQueryResult, keepPreviousData, useQuery } from '@tanstack/react-query'

import { ApplicationException } from '@/common/exceptions'
import { useErrorHandler } from '@/common/hooks'

import {
  ListClassLevelHookParams,
  ListClassLevelHookResult,
  ListSchoolClassHookParams,
  ListSchoolClassHookResult,
  ListSchoolClassStudentHookParams,
  ListSchoolClassStudentHookResult
} from './contracts/hooks'
import {
  listClassLevelService,
  listSchoolClassService,
  listSchoolClassStudentsService
} from './services'

export const hookKey = 'school-classes'

export function useListClassLevel({
  queryOptions,
  onSuccess,
  onError
}: ListClassLevelHookParams): UseQueryResult<ListClassLevelHookResult, ApplicationException> {
  const { handleError } = useErrorHandler()

  return useQuery({
    queryKey: [hookKey, 'listClassLevel'],
    placeholderData: keepPreviousData,
    queryFn: async () => {
      try {
        const listedClassLevel = await listClassLevelService()
        onSuccess?.(listedClassLevel)
        return listedClassLevel
      } catch (error) {
        const parsedError = error as ApplicationException
        handleError({ error: parsedError })
        onError?.({ error: parsedError })
        throw parsedError
      }
    },
    ...queryOptions
  })
}

export function useListSchoolClass({
  model,
  queryOptions,
  onSuccess,
  onError
}: ListSchoolClassHookParams): UseQueryResult<ListSchoolClassHookResult, ApplicationException> {
  const { handleError } = useErrorHandler()

  return useQuery({
    queryKey: [hookKey, 'list', model],
    placeholderData: keepPreviousData,
    queryFn: async () => {
      try {
        const listedSchoolClass = await listSchoolClassService(model)
        onSuccess?.(listedSchoolClass)
        return listedSchoolClass
      } catch (error) {
        const parsedError = error as ApplicationException
        handleError({ error: parsedError })
        onError?.({ error: parsedError })
        throw parsedError
      }
    },
    ...queryOptions
  })
}

export function useListSchoolClassStudent({
  model,
  queryOptions,
  onSuccess,
  onError
}: ListSchoolClassStudentHookParams): UseQueryResult<
  ListSchoolClassStudentHookResult,
  ApplicationException
> {
  const { handleError } = useErrorHandler()

  return useQuery({
    queryKey: [hookKey, 'listStudent', model],
    placeholderData: keepPreviousData,
    queryFn: async () => {
      try {
        const listedSchoolClassStudents = await listSchoolClassStudentsService(model)
        onSuccess?.(listedSchoolClassStudents)
        return listedSchoolClassStudents
      } catch (error) {
        const parsedError = error as ApplicationException
        handleError({ error: parsedError })
        onError?.({ error: parsedError })
        throw parsedError
      }
    },
    ...queryOptions
  })
}
