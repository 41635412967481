import { Svg } from '@positivote/design-system/icons/Base'
import { IconProps } from '@positivote/design-system/icons/types'

export function HubLogo(props: Omit<IconProps, 'fill' | 'ref'>): JSX.Element {
  return (
    <Svg
      viewBox="0 0 966 260"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      css={{ fill: 'none', pointerEvents: 'none', width: 966, height: 260 }}
      {...props}
    >
      <g clipPath="url(#clip0_23889_24401)">
        <path
          d="M955.281 257.964H961.642C963.256 257.964 964.554 256.667 964.554 255.085L964.807 176.763C964.807 176.003 964.49 175.244 963.952 174.706C963.414 174.168 962.655 173.851 961.895 173.851H955.535C953.921 173.851 952.623 175.149 952.623 176.731L952.37 255.053C952.37 255.812 952.687 256.572 953.225 257.11C953.763 257.648 954.522 257.964 955.281 257.964Z"
          fill="#8F52A1"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M413.358 258.629C429.718 258.629 443.516 245.844 444.686 229.705C444.781 229.42 444.813 229.104 444.813 228.819V176.889C444.813 175.244 443.484 173.915 441.838 173.915H435.446C433.801 173.915 432.471 175.244 432.471 176.889V202.142C425.383 196.731 416.079 194.547 407.282 196.256L406.955 196.33C406.361 196.464 405.749 196.602 405.162 196.763C403.991 197.079 402.82 197.459 401.681 197.933L401.331 198.079C400.723 198.33 400.199 198.548 399.719 198.788C399.117 199.073 398.516 199.389 397.788 199.8C397.25 200.085 396.712 200.402 396.206 200.75C395.51 201.193 394.845 201.636 394.244 202.111C386.396 208.123 381.871 217.237 381.871 227.142C381.871 243.819 394.909 257.616 411.586 258.566C412.187 258.629 412.757 258.629 413.358 258.629ZM394.244 227.11C394.244 216.572 402.82 207.997 413.358 207.997V207.965C415.953 207.965 418.453 208.471 420.794 209.452L421.649 209.832C422.186 210.117 422.725 210.402 423.263 210.718C424.56 211.509 425.794 212.49 426.87 213.566L427.009 213.715L427.188 213.903C427.502 214.236 427.821 214.573 428.104 214.927C428.199 215.053 428.294 215.171 428.388 215.29C428.483 215.408 428.579 215.528 428.674 215.655C429.212 216.414 429.718 217.205 430.161 217.996C430.446 218.534 430.699 219.103 430.952 219.673C431.933 222.014 432.44 224.547 432.44 227.11V227.965L432.376 228.787L432.282 229.737C431.016 239.135 422.851 246.224 413.358 246.224H412.535L411.681 246.161L411.6 246.153C411.275 246.124 410.926 246.092 410.605 246.034C401.269 244.673 394.244 236.54 394.244 227.11Z"
          fill="#8F52A1"
        />
        <path
          d="M716.93 183.123C716.93 187.554 720.538 191.161 724.968 191.161C729.398 191.161 733.006 187.554 733.006 183.123C733.006 178.693 729.398 175.085 724.968 175.085C720.538 175.085 716.93 178.693 716.93 183.123Z"
          fill="#8F52A1"
        />
        <path
          d="M483.8 258.66C484.401 258.724 484.971 258.724 485.572 258.724L485.445 258.755C492.471 258.755 499.053 256.509 504.559 252.268V254.452C504.559 256.097 505.888 257.426 507.534 257.426H513.895C515.54 257.426 516.869 256.097 516.869 254.452V199.737C516.869 198.092 515.54 196.763 513.895 196.763H507.534C505.888 196.763 504.559 198.092 504.559 199.737V228.091L504.496 228.946C504.496 229.157 504.468 229.368 504.44 229.579L504.43 229.652C504.419 229.733 504.409 229.814 504.401 229.895C503.135 239.294 494.971 246.382 485.477 246.382H484.654L483.832 246.319C483.483 246.287 483.104 246.256 482.756 246.192C473.42 244.832 466.395 236.699 466.395 227.268V199.737C466.395 198.092 465.066 196.763 463.42 196.763H457.06C455.414 196.763 454.085 198.092 454.085 199.737V228.914C454.085 229.23 454.117 229.515 454.18 229.61C455.351 245.275 468.072 257.774 483.8 258.66Z"
          fill="#8F52A1"
        />
        <path
          d="M532.28 250.116C538.198 256.066 546.109 259.325 554.527 259.325V259.357C562.945 259.357 570.856 256.097 576.773 250.18C577.849 249.104 577.976 247.426 577.09 246.256C577.027 246.192 576.742 245.876 576.742 245.876L572.28 241.414C571.109 240.243 569.21 240.243 568.039 241.414C564.432 245.021 559.622 247.015 554.527 247.015C549.432 247.015 544.622 245.021 541.014 241.414C533.546 233.946 533.546 221.825 541.014 214.357C544.622 210.75 549.432 208.756 554.527 208.756C559.622 208.756 564.432 210.75 568.039 214.357C568.672 214.99 569.274 215.686 569.843 216.446C570.35 217.142 571.141 217.585 572.027 217.648C572.881 217.743 573.736 217.395 574.337 216.794L578.894 212.237C579.97 211.161 580.065 209.452 579.115 208.25C578.387 207.332 577.596 206.446 576.773 205.623C570.824 199.674 562.913 196.414 554.527 196.414C546.141 196.414 538.23 199.705 532.28 205.623C526.331 211.541 523.072 219.484 523.072 227.87C523.072 236.256 526.363 244.167 532.28 250.116Z"
          fill="#8F52A1"
        />
        <path
          d="M662.026 250.116C667.943 256.066 675.855 259.325 684.272 259.325V259.357C692.69 259.357 700.569 256.097 706.519 250.148C707.626 249.04 707.69 247.237 706.677 246.066L702.025 241.414C700.854 240.243 698.956 240.243 697.785 241.414C694.177 245.021 689.367 247.015 684.272 247.015C679.177 247.015 674.367 245.021 670.76 241.414C663.291 233.946 663.291 221.825 670.76 214.357C674.367 210.75 679.177 208.756 684.272 208.756C689.367 208.756 694.177 210.75 697.785 214.357C698.418 214.99 699.019 215.686 699.588 216.446C700.095 217.142 700.886 217.585 701.772 217.648C702.626 217.743 703.481 217.395 704.082 216.794L708.639 212.237C709.715 211.161 709.81 209.452 708.86 208.25C708.133 207.332 707.342 206.446 706.519 205.623C700.569 199.674 692.658 196.414 684.272 196.414C675.886 196.414 667.975 199.705 662.026 205.623C656.076 211.541 652.817 219.484 652.817 227.87C652.817 236.256 656.076 244.167 662.026 250.116Z"
          fill="#8F52A1"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M612.248 259.262C612.849 259.325 613.419 259.325 614.02 259.325L614.052 259.357C621.077 259.357 627.659 257.141 633.165 252.901V255.085C633.165 256.73 634.494 258.059 636.14 258.059H642.501C644.146 258.059 645.475 256.73 645.475 255.085V227.87C645.475 225.75 645.254 223.598 644.842 221.541C644.747 221.034 644.621 220.528 644.494 220.022C643.861 217.522 642.912 215.117 641.678 212.87L640.918 211.541C640.127 210.243 639.241 209.009 638.292 207.838C636.994 206.288 635.57 204.832 634.02 203.566C626.868 197.617 617.121 195.18 607.944 196.952L607.618 197.026C607.023 197.16 606.412 197.298 605.824 197.459C604.653 197.775 603.482 198.187 602.343 198.63L601.993 198.775C601.386 199.027 600.86 199.244 600.381 199.484C599.78 199.769 599.178 200.085 598.45 200.497C597.912 200.781 597.374 201.098 596.868 201.446C596.172 201.889 595.507 202.332 594.906 202.807C587.058 208.819 582.533 217.933 582.533 227.838C582.533 244.515 595.571 258.312 612.248 259.262ZM594.906 227.838C594.906 217.3 603.482 208.724 614.02 208.724V208.756C616.583 208.756 619.115 209.262 621.456 210.243L622.311 210.623C622.849 210.876 623.387 211.193 623.925 211.509C625.222 212.3 626.456 213.25 627.532 214.326C627.975 214.769 628.387 215.212 628.767 215.686C628.861 215.812 628.955 215.93 629.049 216.048C629.144 216.167 629.241 216.288 629.336 216.414C629.874 217.142 630.38 217.933 630.823 218.724C631.108 219.262 631.361 219.832 631.615 220.401C632.596 222.743 633.102 225.243 633.102 227.838V228.661L633.039 229.515L632.944 230.465C631.678 239.863 623.513 246.952 614.02 246.952H613.197L612.374 246.888C611.995 246.857 611.615 246.825 611.267 246.762C601.931 245.401 594.906 237.268 594.906 227.838Z"
          fill="#8F52A1"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M910.535 259.262C911.136 259.325 911.706 259.325 912.307 259.325V259.357C919.364 259.357 925.915 257.141 931.421 252.901V255.085C931.421 256.73 932.75 258.059 934.396 258.059H940.756C942.402 258.059 943.731 256.73 943.731 255.085V227.87C943.731 225.75 943.51 223.629 943.098 221.541C943.003 221.034 942.877 220.528 942.75 220.022C942.117 217.522 941.168 215.117 939.934 212.87L939.174 211.541C938.383 210.243 937.497 209.009 936.548 207.838C935.25 206.288 933.826 204.864 932.275 203.566C925.124 197.617 915.377 195.18 906.2 196.952L905.873 197.026C905.278 197.161 904.668 197.298 904.08 197.459C902.909 197.775 901.738 198.187 900.599 198.63L900.249 198.775C899.642 199.026 899.117 199.244 898.637 199.484C898.035 199.769 897.434 200.085 896.706 200.497C896.168 200.781 895.63 201.098 895.124 201.446C894.459 201.889 893.795 202.332 893.194 202.807C885.346 208.819 880.82 217.965 880.82 227.87C880.82 244.515 893.89 258.312 910.535 259.262ZM893.194 227.838C893.194 217.305 901.761 208.733 912.292 208.724C914.85 208.726 917.375 209.233 919.712 210.212L920.567 210.591C921.105 210.845 921.643 211.161 922.181 211.478C923.478 212.3 924.712 213.25 925.788 214.326C926.231 214.769 926.643 215.212 927.022 215.686C927.212 215.908 927.402 216.161 927.592 216.414C928.13 217.142 928.636 217.933 929.079 218.724L929.222 219.009C929.458 219.475 929.686 219.927 929.87 220.401C930.851 222.743 931.358 225.243 931.358 227.838V228.661L931.294 229.515L931.2 230.465C929.934 239.863 921.769 246.952 912.276 246.952H911.453L910.63 246.888C910.282 246.857 909.902 246.825 909.554 246.762C900.219 245.401 893.194 237.268 893.194 227.838Z"
          fill="#8F52A1"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M769.651 259.262C770.252 259.325 770.822 259.325 771.423 259.325L771.391 259.357C788.068 259.357 801.866 246.319 802.815 229.642C802.878 229.041 802.878 228.471 802.878 227.87C802.878 225.75 802.657 223.629 802.245 221.541C802.151 221.036 802.025 220.532 801.899 220.028L801.897 220.022C801.264 217.522 800.315 215.117 799.081 212.87L798.321 211.541C797.53 210.243 796.644 209.009 795.695 207.838C794.397 206.288 792.973 204.832 791.423 203.566C784.271 197.617 774.524 195.18 765.347 196.952L765.021 197.026C764.426 197.16 763.815 197.298 763.227 197.459C762.056 197.775 760.885 198.155 759.746 198.63L759.397 198.775C758.789 199.026 758.264 199.244 757.784 199.484C757.183 199.769 756.581 200.085 755.854 200.497C755.316 200.781 754.778 201.098 754.271 201.446C753.575 201.857 752.942 202.332 752.309 202.807C744.461 208.819 739.936 217.933 739.936 227.838C739.936 244.515 752.974 258.312 769.651 259.262ZM752.309 227.838C752.309 217.3 760.885 208.724 771.423 208.724V208.693C773.986 208.693 776.518 209.199 778.86 210.18L779.714 210.56C780.252 210.813 780.79 211.129 781.328 211.446C782.625 212.269 783.86 213.218 784.935 214.294C785.379 214.737 785.79 215.18 786.17 215.655C786.265 215.781 786.359 215.9 786.454 216.019C786.549 216.137 786.644 216.256 786.739 216.383C787.277 217.142 787.784 217.901 788.227 218.724C788.511 219.262 788.765 219.832 789.018 220.401C789.999 222.743 790.505 225.243 790.505 227.838V228.661L790.442 229.515L790.347 230.465C789.081 239.863 780.917 246.952 771.423 246.952H770.6L769.777 246.888C769.398 246.857 769.018 246.825 768.67 246.762C759.335 245.401 752.309 237.268 752.309 227.838Z"
          fill="#8F52A1"
        />
        <path
          d="M864.27 258.123H870.726C872.371 258.123 873.7 256.793 873.7 255.148V226.92C873.7 226.604 873.669 226.319 873.605 226.224C872.434 210.528 859.713 198.028 843.985 197.142C843.384 197.079 842.815 197.079 842.213 197.079C835.188 197.079 828.606 199.326 823.1 203.566V201.13C823.1 199.484 821.77 198.155 820.125 198.155H813.764C812.119 198.155 810.79 199.484 810.79 201.13V255.148C810.79 256.793 812.119 258.123 813.764 258.123H820.125C821.77 258.123 823.1 256.793 823.1 255.148V227.743L823.163 226.889C823.163 226.678 823.191 226.467 823.219 226.256C823.233 226.15 823.247 226.045 823.258 225.939C824.524 216.541 832.688 209.452 842.182 209.452H843.004L843.859 209.516L843.939 209.523C844.264 209.552 844.613 209.584 844.935 209.642C854.27 211.003 861.295 219.136 861.295 228.566V255.148C861.295 256.793 862.624 258.123 864.27 258.123Z"
          fill="#8F52A1"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M342.378 258.66C342.979 258.724 343.548 258.724 344.15 258.724L344.118 258.787C352.314 258.787 360.067 255.654 365.953 249.958C366.523 249.42 366.871 248.629 366.871 247.838C366.871 247.015 366.555 246.256 365.985 245.686L361.491 241.192C360.352 240.021 358.485 240.021 357.314 241.129C353.738 244.547 349.055 246.414 344.118 246.414H343.295L342.441 246.35C342.061 246.319 341.681 246.287 341.333 246.224C334.213 245.18 328.264 240.211 325.985 233.408H372.409C373.928 233.408 375.225 232.268 375.384 230.749C375.447 230.211 375.51 229.642 375.542 229.041C375.605 228.439 375.605 227.87 375.605 227.268C375.605 225.148 375.384 223.028 374.972 220.939C374.877 220.433 374.751 219.927 374.624 219.42C373.991 216.92 373.042 214.515 371.808 212.269L371.048 210.94C370.257 209.642 369.371 208.408 368.422 207.237C367.156 205.686 365.7 204.262 364.149 202.965C356.998 197.047 347.346 194.547 338.074 196.351L337.747 196.425C337.152 196.559 336.541 196.697 335.954 196.857C334.814 197.174 333.612 197.554 332.473 198.028L332.123 198.174C331.515 198.425 330.99 198.643 330.511 198.883C329.909 199.168 329.308 199.484 328.58 199.895C328.042 200.18 327.504 200.497 326.998 200.845C326.302 201.256 325.669 201.731 325.036 202.205C317.188 208.218 312.663 217.332 312.663 227.237C312.663 243.914 325.701 257.711 342.378 258.66ZM362.282 221.129H326.049V221.098C328.675 213.376 335.954 208.091 344.181 208.091C346.776 208.091 349.276 208.598 351.618 209.579L352.472 209.959C353.01 210.212 353.548 210.528 354.086 210.845C355.384 211.636 356.618 212.617 357.694 213.693C358.137 214.136 358.548 214.579 358.928 215.053C359.023 215.18 359.118 215.299 359.213 215.417C359.308 215.536 359.403 215.655 359.498 215.781C360.036 216.541 360.542 217.3 360.985 218.123C361.27 218.661 361.523 219.231 361.776 219.8C361.966 220.243 362.124 220.686 362.282 221.129Z"
          fill="#8F52A1"
        />
        <path
          d="M721.075 258.123H727.436C729.05 258.123 730.348 256.825 730.348 255.243L730.601 200.117C730.601 199.357 730.284 198.598 729.746 198.06C729.208 197.522 728.48 197.206 727.689 197.206H721.329C719.715 197.206 718.417 198.503 718.417 200.085L718.164 255.211C718.164 256.002 718.481 256.73 719.019 257.268C719.557 257.806 720.316 258.123 721.075 258.123Z"
          fill="#8F52A1"
        />
        <path
          d="M377.915 133.314H403.896L403.864 133.282V75.7196C403.864 45.7833 394.529 34.6441 369.371 34.6441C359.15 34.6441 347.029 37.8403 338.454 42.7453V0.119251H312.473V133.345H338.454V65.2767C344.719 60.878 352.789 58.378 360.7 58.378C373.422 58.378 377.915 63.8843 377.915 79.3904V133.314Z"
          fill="#8F52A1"
        />
        <path
          d="M419.212 92.2067C419.212 123.409 429.592 135.561 456.3 135.561V135.529C466.332 135.529 476.933 132.554 485.635 127.301V133.282H510.572V36.7011H484.433V104.833C478.705 109.548 471.933 111.827 463.768 111.827C450.509 111.827 445.351 105.561 445.351 89.4219V36.7011H419.212V92.2067Z"
          fill="#8F52A1"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M553.451 127.649C560.128 132.649 569.242 135.371 579.495 135.371L579.527 135.402C609.052 135.402 627.374 116.036 627.374 84.9283C627.374 53.8211 609.241 34.4859 580.033 34.4859C570.413 34.4859 560.666 37.2707 553.451 42.0491V0.119251H527.66V133.282H553.451V127.649ZM575.476 112.017C566.172 112.017 558.229 109.137 553.071 103.884V66.321C558.198 61.0995 566.362 57.9983 574.97 57.9983C591.52 57.9983 601.014 68.2197 601.014 84.9283C601.014 101.637 591.235 112.017 575.476 112.017Z"
          fill="#8F52A1"
        />
        <path
          d="M84.6399 127.812C85.2096 128.16 85.7159 127.938 85.9375 127.812C86.1907 127.653 86.6021 127.305 86.5388 126.609C85.7476 115.975 85.5893 106.228 86.0641 97.6513C86.0641 97.3031 85.9058 96.9234 85.621 96.6385C77.6774 89.6127 74.6076 82.0806 74.6076 69.8013C74.6076 49.8633 91.6024 34.8623 114.136 34.8623C116.383 34.8623 118.598 34.9888 120.718 35.2104C121.035 35.242 121.351 35.2104 121.604 35.0205C122.142 34.704 122.649 34.3875 123.187 34.0711C133.915 27.8681 144.992 24.3236 156.069 23.5324C156.86 23.4691 157.145 22.9311 157.208 22.583C157.303 22.2665 157.335 21.6335 156.67 21.1905C144.992 13.4051 130.782 9.44916 114.452 9.44916C94.8938 9.44916 78.6269 15.2723 66.0944 26.7288C53.4353 38.3119 47.0425 53.028 47.0425 70.4975C47.0425 87.9671 52.2643 102.557 62.5815 112.146C68.0565 117.241 75.4621 122.526 84.5766 127.812H84.6399Z"
          fill="url(#paint0_linear_23889_24401)"
        />
        <path
          d="M149.39 132.843C148.821 132.495 148.314 132.717 148.093 132.843C147.84 133.002 147.428 133.35 147.491 134.046C148.283 144.68 148.441 154.427 147.966 163.004C147.966 163.352 148.124 163.731 148.409 164.016C156.353 171.042 159.423 178.574 159.423 190.854C159.423 210.792 142.428 225.793 119.895 225.793C117.648 225.793 115.432 225.666 113.312 225.444C113.249 225.444 113.185 225.444 113.154 225.444C112.9 225.444 112.647 225.508 112.457 225.634C111.951 225.951 111.413 226.267 110.875 226.584C100.146 232.787 89.0698 236.331 77.9931 237.122C77.2019 237.186 76.9171 237.724 76.8538 238.072C76.7588 238.388 76.7272 239.021 77.3918 239.464C89.0698 247.25 103.28 251.206 119.61 251.206C139.168 251.206 155.435 245.383 167.967 233.926C180.627 222.343 187.019 207.627 187.019 190.157C187.019 172.688 181.798 158.098 171.48 148.509C166.005 143.414 158.6 138.129 149.485 132.843H149.39Z"
          fill="url(#paint1_linear_23889_24401)"
        />
        <path
          d="M232.15 138.097C228.447 121.355 218.889 108.443 203.793 99.7084C188.159 90.6888 173.443 88.2203 159.961 92.3344C152.809 94.5181 144.549 98.3159 135.403 103.538C134.833 103.854 134.77 104.392 134.77 104.677C134.77 104.962 134.896 105.5 135.498 105.785C145.087 110.405 153.6 115.152 160.816 119.868C161.132 120.058 161.512 120.121 161.892 119.995C171.956 116.64 180.026 117.716 190.628 123.856C207.876 133.825 212.402 156.041 201.135 175.568C200.027 177.498 198.793 179.366 197.527 181.106C197.337 181.359 197.242 181.644 197.242 181.961C197.242 182.562 197.242 183.195 197.242 183.796C197.242 196.171 194.774 207.532 189.9 217.564C189.552 218.292 189.9 218.799 190.153 219.02C190.406 219.273 190.913 219.621 191.641 219.242C204.237 213.039 214.744 202.722 222.909 188.575C232.688 171.644 235.789 154.649 232.118 138.065L232.15 138.097Z"
          fill="url(#paint2_linear_23889_24401)"
        />
        <path
          d="M30.2692 160.978C45.9031 169.998 60.6193 172.466 74.1012 168.352C81.2536 166.168 89.5136 162.371 98.6598 157.149C99.2295 156.832 99.2928 156.294 99.2928 156.01C99.2928 155.725 99.1662 155.187 98.5649 154.902C88.9756 150.281 80.4624 145.534 73.2467 140.819C72.9303 140.629 72.5505 140.566 72.1707 140.692C62.1068 144.047 54.0366 142.971 43.4346 136.831C26.1866 126.862 21.661 104.645 32.9276 85.1188C34.0353 83.1883 35.2695 81.3211 36.5354 79.5804C36.5671 79.5488 36.5987 79.4855 36.6304 79.4538C36.757 79.2323 36.8203 78.9791 36.8203 78.7576C36.8203 78.1563 36.8203 77.5233 36.8203 76.922C36.8203 64.5478 39.2888 53.1863 44.1309 43.1539C44.479 42.4261 44.1309 41.9197 43.8777 41.6982C43.6245 41.445 43.1181 41.0968 42.3902 41.4766C29.7945 47.6796 19.2874 57.9967 11.1223 72.1432C1.31156 89.0114 -1.78992 106.006 1.88121 122.59C5.58399 139.331 15.1416 152.244 30.2375 160.978H30.2692Z"
          fill="url(#paint3_linear_23889_24401)"
        />
        <path
          d="M128.189 186.455C129.866 179.144 130.72 170.124 130.752 159.554C130.752 158.89 130.341 158.573 130.087 158.415C129.834 158.288 129.328 158.098 128.758 158.478C119.96 164.491 111.605 169.492 103.915 173.353C103.598 173.511 103.345 173.859 103.282 174.239C101.161 184.619 96.1927 191.075 85.5591 197.215C68.3111 207.184 46.7907 199.968 35.5241 180.442C34.4164 178.511 33.4037 176.517 32.5176 174.555C32.391 174.27 32.1694 174.017 31.9163 173.891C31.3783 173.606 30.8402 173.289 30.3022 172.973C19.5737 166.77 10.9655 158.953 4.7309 149.743C4.28783 149.079 3.65488 149.142 3.3384 149.205C3.02192 149.3 2.45226 149.553 2.38897 150.376C1.47119 164.396 5.14232 178.669 13.3074 192.816C23.0866 209.747 36.252 220.919 52.4556 226.046C68.8175 231.204 84.7679 229.401 99.8638 220.666C115.498 211.646 124.992 200.126 128.157 186.391L128.189 186.455Z"
          fill="url(#paint4_linear_23889_24401)"
        />
        <path
          d="M105.306 74.0737C103.629 81.3843 102.774 90.4039 102.742 100.974C102.742 101.639 103.154 101.955 103.407 102.082C103.66 102.209 104.167 102.398 104.736 102.019C113.534 96.0056 121.889 91.0052 129.58 87.1442C129.896 86.986 130.149 86.6379 130.213 86.2581C132.333 75.8776 137.302 69.4215 147.935 63.2819C165.183 53.3128 186.704 60.5285 197.97 80.0551C199.078 81.9856 200.091 83.9795 200.977 85.9416C200.977 86.0049 201.04 86.0366 201.04 86.0999C201.167 86.3214 201.357 86.5113 201.546 86.6062C202.084 86.891 202.622 87.2075 203.16 87.524C213.889 93.7269 222.497 101.544 228.732 110.753C229.175 111.418 229.808 111.355 230.124 111.291C230.441 111.196 231.01 110.943 231.074 110.12C231.992 96.1005 228.32 81.8274 220.155 67.6809C210.376 50.7494 197.211 39.5778 181.007 34.4508C164.645 29.2923 148.695 31.0962 133.599 39.8309C117.965 48.8505 108.471 60.3703 105.306 74.1054V74.0737Z"
          fill="url(#paint5_linear_23889_24401)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_23889_24401"
          x1="-5.73645"
          y1="130.317"
          x2="248.979"
          y2="130.317"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FA9B1E" />
          <stop offset="0.37" stopColor="#E15730" />
          <stop offset="0.69" stopColor="#BC44A0" />
          <stop offset="1" stopColor="#8F52A1" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_23889_24401"
          x1="-5.73645"
          y1="130.317"
          x2="248.979"
          y2="130.317"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FA9B1E" />
          <stop offset="0.37" stopColor="#E15730" />
          <stop offset="0.69" stopColor="#BC44A0" />
          <stop offset="1" stopColor="#8F52A1" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_23889_24401"
          x1="-5.73645"
          y1="130.317"
          x2="248.979"
          y2="130.317"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FA9B1E" />
          <stop offset="0.37" stopColor="#E15730" />
          <stop offset="0.69" stopColor="#BC44A0" />
          <stop offset="1" stopColor="#8F52A1" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_23889_24401"
          x1="-5.73645"
          y1="130.317"
          x2="248.979"
          y2="130.317"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FA9B1E" />
          <stop offset="0.37" stopColor="#E15730" />
          <stop offset="0.69" stopColor="#BC44A0" />
          <stop offset="1" stopColor="#8F52A1" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_23889_24401"
          x1="-5.73645"
          y1="130.317"
          x2="248.979"
          y2="130.317"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FA9B1E" />
          <stop offset="0.37" stopColor="#E15730" />
          <stop offset="0.69" stopColor="#BC44A0" />
          <stop offset="1" stopColor="#8F52A1" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_23889_24401"
          x1="-5.73645"
          y1="130.317"
          x2="248.979"
          y2="130.317"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FA9B1E" />
          <stop offset="0.37" stopColor="#E15730" />
          <stop offset="0.69" stopColor="#BC44A0" />
          <stop offset="1" stopColor="#8F52A1" />
        </linearGradient>
        <clipPath id="clip0_23889_24401">
          <rect width="965.556" height="260" fill="white" transform="translate(0.222198)" />
        </clipPath>
      </defs>
    </Svg>
  )
}
