import { ClassLevelCode } from '@/modules/hub/school-classes/contracts'

export enum OnboardingOrgType {
  SCHOOL = 'SCHOOL',
  TEACHING_NETWORK = 'TEACHING_NETWORK'
}

export enum OnboardingOrgNetworkType {
  PRIVATE = 'PRIVATE',
  PUBLIC = 'PUBLIC'
}

export enum OnboardingOrgStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE'
}

export enum OnboardingInRegisterStageKey {
  USER_MASTER_CREATED = 'USER_MASTER_CREATED',
  USER_MASTER_NOT_CREATED = 'USER_MASTER_NOT_CREATED',
  SCHOOL_CREATED = 'SCHOOL_CREATED',
  SCHOOL_NOT_CREATED = 'SCHOOL_NOT_CREATED',
  CURRENT_PERIOD_ACTIVATED = 'CURRENT_PERIOD_ACTIVATED',
  CURRENT_PERIOD_NOT_ACTIVATED = 'CURRENT_PERIOD_NOT_ACTIVATED',
  SCHOOL_ENROLLMENTS_STARTED = 'SCHOOL_ENROLLMENTS_STARTED',
  SCHOOL_ENROLLMENTS_NOT_STARTED = 'SCHOOL_ENROLLMENTS_NOT_STARTED',
  IMPORT_MODE_SETUP_CONFIGURED = 'IMPORT_MODE_SETUP_CONFIGURED',
  IMPORT_MODE_DEFAULT_CONFIGURED = 'IMPORT_MODE_DEFAULT_CONFIGURED',
  LICENSES_CONFIGURED = 'LICENSES_CONFIGURED',
  LICENSES_NOT_CONFIGURED = 'LICENSES_NOT_CONFIGURED'
}

export enum OnboardingInRegisterStageStatus {
  TODO = 'TODO',
  COMPLETED = 'COMPLETED'
}

export enum OnboardingInRegisterStatus {
  ACTIVE = 'ACTIVE',
  CANCELED = 'CANCELED',
  INACTIVE = 'INACTIVE'
}

export interface OnboardingPreRegisterOrg {
  name: string
  alias: string
  type: OnboardingOrgType
  networkType: OnboardingOrgNetworkType
  cinNumber: string
  inepCode: number
  address: {
    street: string
    city: string
    state: string
    country: string
  }
}

export interface OnboardingPreRegister {
  org: OnboardingPreRegisterOrg
  user: {
    name: string
    email: string
  }
  control: {
    code: string
  }
  status: string
}

export interface OnboardingPreRegisterFormatted extends Omit<OnboardingPreRegister, 'org'> {
  org: OnboardingPreRegisterOrg & {
    typeFormatted: string
    networkTypeFormatted: string
    cinNumberFormatted: string
    addressFormatted: string
  }
}

export interface OnboardingInRegisterStage {
  key: OnboardingInRegisterStageKey
  status: OnboardingInRegisterStageStatus
  updatedAt: string | null
}

export interface OnboardingInRegisterStageFormatted extends OnboardingInRegisterStage {
  keyFormatted: string
  statusFormatted: string
  updatedAtFormatted: string | null
}

export interface OnboardingInRegisterDetails {
  id: number
  code: string
  type: OnboardingOrgType
  networkType: OnboardingOrgNetworkType
  name: string
  stages: OnboardingInRegisterStage[]
  students: Array<{
    key: OnboardingInRegisterStatus
    total: number
  }>
  professionals: Array<{
    key: string
    total: number
  }>
  classLevels: Array<{
    key: keyof typeof ClassLevelCode
    total: number
  }>
  status: OnboardingInRegisterStatus
  createdAt: string
  updatedAt: string
}

export interface OnboardingInRegisterDetailsFormatted
  extends Omit<OnboardingInRegisterDetails, 'stages'> {
  professionalProfilesQuantity: number
  studentsQuantity: number
  mostRecentStage: OnboardingInRegisterStageFormatted | null
  schoolCreatedStage: OnboardingInRegisterStageFormatted
  userMasterCreatedStage: OnboardingInRegisterStageFormatted
  periodActivatedStage: OnboardingInRegisterStageFormatted
  schoolEnrollmentStage: OnboardingInRegisterStageFormatted
  stages: OnboardingInRegisterStageFormatted[]
}
