import { ClassLevelCode } from '@/modules/hub/school-classes/contracts'

export const schoolClassPt = {
  resources: {
    listClassLevel: 'Listar anos escolares',
    listSchoolClass: 'Listar turmas'
  },
  components: {
    filter: {
      title: 'Opções de filtragem',
      input: {
        search: 'Buscar turma...',
        select: 'Selecionar ano escolar'
      }
    },
    header: {
      selectClass: 'Selecione uma turma',
      code: 'Código',
      level: 'Nível'
    },
    emptyList: 'Ops! Ainda não temos turmas para exibir'
  },

  fullClassLevel: <
    Record<
      | keyof typeof ClassLevelCode
      | 'EF1_ESP'
      | 'EF1'
      | 'EF2_ESP'
      | 'EF2'
      | 'EI_ESP'
      | 'EI'
      | 'EJA'
      | 'EM_ESP'
      | 'EM'
      | 'EPRO',
      string
    >
  >{
    CHILD_EDUCATION: ClassLevelCode.CHILD_EDUCATION,
    EI: 'Educação Infantil',
    GRUPO_ONE: ClassLevelCode.GRUPO_ONE,
    GRUPO_TWO: ClassLevelCode.GRUPO_TWO,
    GRUPO_TREE: ClassLevelCode.GRUPO_TREE,
    GRUPO_FOUR: ClassLevelCode.GRUPO_FOUR,
    GRUPO_FIVE: ClassLevelCode.GRUPO_FIVE,
    EI_ESP: 'Educação Infantil Especial',
    GRUPO_ONE_ESP: ClassLevelCode.GRUPO_ONE_ESP,
    GRUPO_TWO_ESP: ClassLevelCode.GRUPO_TWO_ESP,
    GRUPO_TREE_ESP: ClassLevelCode.GRUPO_TREE_ESP,
    GRUPO_FOUR_ESP: ClassLevelCode.GRUPO_FOUR_ESP,
    GRUPO_FIVE_ESP: ClassLevelCode.GRUPO_FIVE_ESP,
    EF1: 'Ensino Fundamental 1',
    FIRST_YEAR: ClassLevelCode.FIRST_YEAR,
    SECOND_YEAR: ClassLevelCode.SECOND_YEAR,
    THIRD_YEAR: ClassLevelCode.THIRD_YEAR,
    FORTH_YEAR: ClassLevelCode.FORTH_YEAR,
    FIFTH_YEAR: ClassLevelCode.FIFTH_YEAR,
    EF2: 'Ensino Fundamental 2',
    SIXTH_YEAR: ClassLevelCode.SIXTH_YEAR,
    SEVENTH_YEAR: ClassLevelCode.SEVENTH_YEAR,
    EIGHTH_YEAR: ClassLevelCode.EIGHTH_YEAR,
    NINTH_YEAR: ClassLevelCode.NINTH_YEAR,
    EF1_ESP: 'Ensino Fundamental 1 Especial',
    FIRST_YEAR_ESP: ClassLevelCode.FIRST_YEAR_ESP,
    SECOND_YEAR_ESP: ClassLevelCode.SECOND_YEAR_ESP,
    THIRD_YEAR_ESP: ClassLevelCode.THIRD_YEAR_ESP,
    FORTH_YEAR_ESP: ClassLevelCode.FORTH_YEAR_ESP,
    FIFTH_YEAR_ESP: ClassLevelCode.FIFTH_YEAR_ESP,
    EF2_ESP: 'Ensino Fundamental 2 Especial',
    SIXTH_YEAR_ESP: ClassLevelCode.SIXTH_YEAR_ESP,
    SEVENTH_YEAR_ESP: ClassLevelCode.SEVENTH_YEAR_ESP,
    EIGHTH_YEAR_ESP: ClassLevelCode.EIGHTH_YEAR_ESP,
    NINTH_YEAR_ESP: ClassLevelCode.NINTH_YEAR_ESP,
    EM: 'Ensino Médio',
    FIRST_YEAR_EM: ClassLevelCode.FIRST_YEAR_EM,
    SECOND_YEAR_EM: ClassLevelCode.SECOND_YEAR_EM,
    THIRD_YEAR_EM: ClassLevelCode.THIRD_YEAR_EM,
    EM_ESP: 'Ensino Médio Especial',
    FIRST_YEAR_EM_ESP: ClassLevelCode.FIRST_YEAR_EM_ESP,
    SECOND_YEAR_EM_ESP: ClassLevelCode.SECOND_YEAR_EM_ESP,
    THIRD_YEAR_EM_ESP: ClassLevelCode.THIRD_YEAR_EM_ESP,
    EJA: 'EJA',
    EJAEF_ONE: ClassLevelCode.EJAEF_ONE,
    EJAEF_SECOND: ClassLevelCode.EJAEF_SECOND,
    EJA_EM: ClassLevelCode.EJA_EM,
    EPRO: 'Educação Profissional',
    PRO: ClassLevelCode.PRO,
    EPEM: ClassLevelCode.EPEM
  },
  levelClassLevel: <
    Record<
      | keyof typeof ClassLevelCode
      | 'EF1_ESP'
      | 'EF1'
      | 'EF2_ESP'
      | 'EF2'
      | 'EI_ESP'
      | 'EI'
      | 'EJA'
      | 'EM_ESP'
      | 'EM'
      | 'EPRO',
      string
    >
  >{
    CHILD_EDUCATION: 'Educação Infantil',
    EI: 'Educação Infantil',
    GRUPO_ONE: 'Grupo 1',
    GRUPO_TWO: 'Grupo 2',
    GRUPO_TREE: 'Grupo 3',
    GRUPO_FOUR: 'Grupo 4',
    GRUPO_FIVE: 'Grupo 5',
    EI_ESP: 'Educação Infantil Especial',
    GRUPO_ONE_ESP: 'Grupo 1 Especial',
    GRUPO_TWO_ESP: 'Grupo 2 Especial',
    GRUPO_TREE_ESP: 'Grupo 3 Especial',
    GRUPO_FOUR_ESP: 'Grupo 4 Especial',
    GRUPO_FIVE_ESP: 'Grupo 5 Especial',
    EF1: 'Ensino Fundamental 1',
    FIRST_YEAR: '1º ano',
    SECOND_YEAR: '2º ano',
    THIRD_YEAR: '3º ano',
    FORTH_YEAR: '4º ano',
    FIFTH_YEAR: '5º ano',
    EF2: 'Ensino Fundamental 2',
    SIXTH_YEAR: '6º ano',
    SEVENTH_YEAR: '7º ano',
    EIGHTH_YEAR: '8º ano',
    NINTH_YEAR: '9º ano',
    EF1_ESP: 'Ensino Fundamental 1 Especial',
    FIRST_YEAR_ESP: '1º ano Especial',
    SECOND_YEAR_ESP: '2º ano Especial',
    THIRD_YEAR_ESP: '3º ano Especial',
    FORTH_YEAR_ESP: '4º ano Especial',
    FIFTH_YEAR_ESP: '5º ano Especial',
    EF2_ESP: 'Ensino Fundamental 2 Especial',
    SIXTH_YEAR_ESP: '6º ano Especial',
    SEVENTH_YEAR_ESP: '7º ano Especial',
    EIGHTH_YEAR_ESP: '8º ano Especial',
    NINTH_YEAR_ESP: '9º ano Especial',
    EM: 'Ensino Médio',
    FIRST_YEAR_EM: '1ª Série',
    SECOND_YEAR_EM: '2ª Série',
    THIRD_YEAR_EM: '3ª Série',
    EM_ESP: 'Ensino Médio Especial',
    FIRST_YEAR_EM_ESP: '1ª Série Especial',
    SECOND_YEAR_EM_ESP: '2ª Série Especial',
    THIRD_YEAR_EM_ESP: '3ª Série Especial',
    EJA: 'EJA',
    EJAEF_ONE: 'EJA Ensino Fundamental 1',
    EJAEF_SECOND: 'EJA Ensino Fundamental 2',
    EJA_EM: 'EJA Ensino Médio',
    EPRO: 'Educação Profissional',
    PRO: 'Educação Profissional',
    EPEM: 'Educação Profissional Integrada ao Ensino Médio'
  }
}
