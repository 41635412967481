import { i18n } from '@/common/i18n'

import { ListSchoolClassApiResult, SchoolClassFormatted } from './contracts'

export function schoolClassFormatSanitizer(
  schoolClasses: ListSchoolClassApiResult['data']
): SchoolClassFormatted[] {
  return schoolClasses.map((schoolClass) => ({
    ...schoolClass,
    levelFormatted: {
      ...schoolClass.level,
      code: i18n().modules.hub.schoolClasses.fullClassLevel[schoolClass.level.code]
    }
  }))
}
