import { Div } from '@positivote/design-system/components/Div'
import { Typography, TypographyVariants } from '@positivote/design-system/components/Typography'
import { useTheme } from '@positivote/design-system/hooks'
import { IconProps } from '@positivote/design-system/icons/types'
import { Breakpoint } from '@positivote/design-system/theme'

import { i18n } from '@/common/i18n'

import { Mascot } from './Mascot'

interface EmptySearchProps {
  titleVariant?: TypographyVariants
  mascotProps?: Omit<IconProps, 'fill'>
}

export function EmptySearch({ titleVariant, mascotProps }: EmptySearchProps): JSX.Element {
  const { breakpoint } = useTheme()

  return (
    <Div
      css={{
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      <Mascot
        css={{
          ...(breakpoint === Breakpoint.xs && { height: '142px', width: '180px' }),
          ...mascotProps?.css
        }}
        {...mascotProps}
      />
      <Typography
        data-testid="Typography-emptySearchTitle"
        variant={titleVariant ?? breakpoint === Breakpoint.xs ? 'titleMedium' : 'headlineSmall'}
        css={{ color: '$on-surface' }}
      >
        {i18n().common.components.emptySearch.title}
      </Typography>
    </Div>
  )
}
