import { formatDatePtBr } from '@/common/helpers'

import {
  CreateSchoolYearApiResult,
  ListSchoolYearApiResult,
  SchoolYearFormatted,
  ShowSchoolYearApiResult
} from './contracts'

export function schoolYearFormatSanitizer(
  schoolYears: ListSchoolYearApiResult['data']
): SchoolYearFormatted[] {
  return schoolYears.map((schoolYear) => ({
    code: schoolYear.code,
    id: schoolYear.id,
    startDate: formatDatePtBr(schoolYear.startDate),
    endDate: formatDatePtBr(schoolYear.endDate),
    status: schoolYear.status,
    statusFormat: schoolYear.status !== 'inactive',
    title: schoolYear.title,
    uuid: schoolYear.uuid,
    statusFormatted: schoolYear.status === 'active' ? 'Ativo' : 'Inativo',
    schoolName: schoolYear.institution_name,
    totalClassRooms: schoolYear.total_class_rooms,
    institutionId: schoolYear.institution_id
  }))
}

export function createSchoolYearApiParamsSanitizer(
  schoolYear: CreateSchoolYearApiResult
): SchoolYearFormatted {
  return {
    code: schoolYear.code,
    id: schoolYear.id,
    startDate: formatDatePtBr(schoolYear.startDate),
    endDate: formatDatePtBr(schoolYear.endDate),
    status: schoolYear.status,
    title: schoolYear.title,
    uuid: schoolYear.uuid,
    statusFormatted: schoolYear.status === 'active' ? 'Ativo' : 'Inativo',
    schoolName: schoolYear.institution_name,
    totalClassRooms: schoolYear.total_class_rooms,
    institutionId: schoolYear.institution_id
  }
}

export function showSchoolYearApiResult(schoolYear: ShowSchoolYearApiResult): SchoolYearFormatted {
  return {
    code: schoolYear.code,
    id: schoolYear.id,
    startDate: formatDatePtBr(schoolYear.startDate),
    endDate: formatDatePtBr(schoolYear.endDate),
    status: schoolYear.status,
    title: schoolYear.title,
    uuid: schoolYear.uuid,
    statusFormatted: schoolYear.status === 'active' ? 'Ativo' : 'Inativo',
    schoolName: schoolYear.institution_name,
    totalClassRooms: schoolYear.total_class_rooms,
    institutionId: schoolYear.institution_id
  }
}
