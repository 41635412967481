import { Div } from '@positivote/design-system/components/Div'
import { Image } from '@positivote/design-system/components/Image'
import { LinkButton } from '@positivote/design-system/components/LinkButton'
import { Typography } from '@positivote/design-system/components/Typography'
import { ONE_POSITIVE_Z_INDEX } from '@positivote/design-system/constants'
import { useTheme } from '@positivote/design-system/hooks'
import { Breakpoint } from '@positivote/design-system/theme'

import { HubLogo } from '@/common/assets/icons/HubLogo'
import { i18n } from '@/common/i18n'
import { useAuth } from '@/modules/hub/auth/contexts'

import { BaseBackgroundBottomLeft } from './BottomLeft'
import { BaseBackgroundBottomLeftMobile } from './BottomLeftMobile'
import { BaseBackgroundBottomRight } from './BottomRight'
import { BaseBackgroundBottomRightMobile } from './BottomRightMobile'
import { EducacionalIcon } from './Educacional'
import { BaseBackgroundTopLeft } from './TopLeft'
import { BaseBackgroundTopLeftMobile } from './TopLeftMobile'
import { BaseBackgroundTopRight } from './TopRight'
import { BaseBackgroundTopRightMobile } from './TopRightMobile'

export interface BaseBackgroundProps {
  children: React.ReactNode
  shouldShowHeader?: boolean
  shouldShowFooter?: boolean
}

export function BaseBackground({
  children,
  shouldShowHeader = true,
  shouldShowFooter = true
}: BaseBackgroundProps): JSX.Element {
  const { breakpoint } = useTheme()
  const { whiteLabel, ssoApplication, isLoadingSsoApplication, loginProps } = useAuth()
  const headerOrBottomPadding = breakpoint === Breakpoint.xs ? 28 : 48

  return (
    <Div
      className="BaseBackground-Container"
      css={{ height: '100%', width: '100%', overflowY: 'auto' }}
    >
      <Div
        id="BaseBackground-Header"
        className="BaseBackground-Header"
        css={{
          display: 'flex',
          justifyContent: 'center',
          width: '100%',
          padding: '$lg',
          position: 'relative',
          '@xs': { padding: '$md' }
        }}
      >
        {!ssoApplication &&
          !isLoadingSsoApplication &&
          (breakpoint === Breakpoint.xs ? (
            <>
              <BaseBackgroundTopLeftMobile
                data-testid="BaseBackground-TopLeftIcon"
                fill="$primary"
                css={{ position: 'absolute', top: 0, left: 0 }}
              />
              <BaseBackgroundTopRightMobile
                data-testid="BaseBackground-TopRightIcon"
                fill="$primary"
                css={{ position: 'absolute', top: 30, right: 0 }}
              />
            </>
          ) : (
            <>
              <BaseBackgroundTopLeft
                data-testid="BaseBackground-TopLeftIcon"
                fill="$primary"
                css={{ position: 'absolute', top: 52, left: 0 }}
              />
              <BaseBackgroundTopRight
                data-testid="BaseBackground-TopRightIcon"
                fill="$primary"
                css={{ position: 'absolute', top: 0, right: 96 }}
              />
            </>
          ))}
        {shouldShowHeader &&
          (whiteLabel?.urlLogo && whiteLabel.active ? (
            <Image
              data-testid="BaseBackground-SchoolLogo"
              alt={whiteLabel.alias}
              src={whiteLabel.urlLogo}
              css={{
                width: 236,
                height: 65,
                objectFit: 'contain',
                '@xs': { width: 192, height: 48 }
              }}
            />
          ) : (
            <HubLogo
              data-testid="BaseBackground-HubLogo"
              css={{ width: 178, height: 48, '@xs': { width: 148, height: 40 } }}
            />
          ))}
      </Div>
      <Div
        id="BaseBackground-Content"
        className="BaseBackground-Content"
        css={{
          display: 'flex',
          flexDirection: 'column',
          padding: '$none $lg',
          position: 'relative',
          zIndex: ONE_POSITIVE_Z_INDEX,
          // DOCS: calc(fullHeight - headerHeight - footerHeight)
          minHeight: `calc(100% - ${document.getElementById('BaseBackground-Header')?.clientHeight ?? headerOrBottomPadding}px - ${document.getElementById('BaseBackground-Footer')?.clientHeight ?? headerOrBottomPadding}px)`,
          '& > *': { margin: 'auto' },
          '@xs': { padding: '$none $md' }
        }}
      >
        {children}
      </Div>
      <Div
        id="BaseBackground-Footer"
        className="BaseBackground-Footer"
        css={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          padding: '$lg',
          gap: '$md',
          position: 'relative',
          '@xs': { gap: '$2xs', padding: '$sm' }
        }}
      >
        {!ssoApplication &&
          !isLoadingSsoApplication &&
          (breakpoint === Breakpoint.xs ? (
            <>
              <BaseBackgroundBottomLeftMobile
                data-testid="BaseBackground-BottomLeftIcon"
                fill="$primary"
                css={{ position: 'absolute', bottom: 40, left: 10 }}
              />
              <BaseBackgroundBottomRightMobile
                data-testid="BaseBackground-BottomRightIcon"
                fill="$primary"
                css={{ position: 'absolute', bottom: 0, right: 0 }}
              />
            </>
          ) : (
            <>
              <BaseBackgroundBottomLeft
                data-testid="BaseBackground-BottomLeftIcon"
                fill="$primary"
                css={{ position: 'absolute', bottom: 68, left: 30 }}
              />
              <BaseBackgroundBottomRight
                data-testid="BaseBackground-BottomRightIcon"
                fill="$primary"
                css={{ position: 'absolute', bottom: 0, right: 0 }}
              />
            </>
          ))}
        {shouldShowFooter && (
          <>
            <EducacionalIcon
              data-testid="BaseBackground-EducacionalIcon"
              css={{ width: 148, height: 24 }}
            />
            <Div
              data-testid="BaseBackground-Copyright"
              css={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '$4xs',
                '@xs': { gap: '$5xs' }
              }}
            >
              <Typography
                data-testid="Typography-BaseBackground-Copyright"
                variant={breakpoint === Breakpoint.xs ? 'labelSmall' : 'bodySmall'}
                css={{ color: '$on-surface-variant' }}
              >
                {i18n().common.layouts.baseBackground.footer.copyright}
              </Typography>
              <Div css={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                {loginProps?.loginType === 'normal' && (
                  <>
                    <LinkButton
                      data-testid="systemRequirementsChecker"
                      typographyVariant={breakpoint === Breakpoint.xs ? 'labelSmall' : 'bodySmall'}
                      onClick={() =>
                        window.open(import.meta.env.VITE_HUB_REQUIREMENTS_SYSTEM_CHECKER_URL)
                      }
                    >
                      {i18n().common.layouts.baseBackground.footer.systemRequirementsChecker}
                    </LinkButton>
                    <Typography
                      data-testid="Typography-pipe"
                      variant={breakpoint === Breakpoint.xs ? 'labelSmall' : 'bodySmall'}
                      css={{ color: '$on-surface-variant' }}
                    >
                      |
                    </Typography>
                  </>
                )}
                <LinkButton
                  data-testid="terms"
                  typographyVariant={breakpoint === Breakpoint.xs ? 'labelSmall' : 'bodySmall'}
                  onClick={() => window.open(import.meta.env.VITE_HUB_TERMS_URL, '_blank')}
                >
                  {i18n().common.layouts.baseBackground.footer.terms}
                </LinkButton>
              </Div>
            </Div>
          </>
        )}
      </Div>
    </Div>
  )
}
