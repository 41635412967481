import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { BrowserRouter } from 'react-router-dom'

import { STALE_TIME } from './common/constants/react-query'
import { Contexts } from './common/contexts'
import { Routes } from './common/routes'

export function App(): JSX.Element {
  return (
    <QueryClientProvider
      client={
        new QueryClient({
          defaultOptions: {
            queries: {
              staleTime: STALE_TIME,
              retry: false,
              refetchOnWindowFocus: false
            }
          }
        })
      }
    >
      <BrowserRouter>
        <Contexts>
          <Routes />
        </Contexts>
      </BrowserRouter>
      {import.meta.env.MODE !== 'test' && <ReactQueryDevtools />}
    </QueryClientProvider>
  )
}
