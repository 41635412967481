import { AppCard } from '@/modules/hub/applications/components/AppCard'

import { HubLabAppCardProps } from './styles'

export function HubLabMobileAppCard({
  isHighlight,
  css,
  ...restProps
}: HubLabAppCardProps): JSX.Element {
  return (
    <AppCard
      wrapperProps={{ css: { width: '100%' } }}
      css={{
        width: '100%',
        height: 'unset',
        backgroundColor: '$alpha-colors-white-40',
        borderRadius: '$lg',
        '& .Button-StateLayer': {
          gap: '$md',
          flexDirection: 'row',
          justifyContent: 'flex-start',
          '& .MobileAppCard-Application-Icon': {
            minWidth: '48px',
            maxWidth: '48px',
            minHeight: '48px',
            maxHeight: '48px'
          }
        },
        ...(isHighlight && {
          '& .Button-StateLayer': {
            gap: '$2xs',
            flexDirection: 'column',
            justifyContent: 'center'
          }
        }),
        ...css
      }}
      {...restProps}
    />
  )
}
