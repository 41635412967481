export const usersPt = {
  pages: {
    list: {
      pageTitle: 'Meus usuários',
      appBar: {
        title: 'Gerenciamento de dados',
        breadcrumbs: {
          overview: 'Visão geral',
          title: 'Meus usuários'
        }
      },
      filter: {
        searchLabel: 'Buscar...',
        title: 'Opções de filtragem',
        select: 'Selecionar perfil',
        searching: 'Buscando por resultados...',
        searchingResult: 'Resultado da busca...'
      },
      header: {
        username: 'Nome do usuário',
        enrollment: 'Matrícula',
        email: 'E-mail',
        imageUsageTerm: 'Termo de uso de imagem',
        profile: 'Perfil'
      },
      body: {
        switchLabel: (permission: boolean): string => (permission ? 'Permite' : 'Não permite')
      },
      emptyList: 'Ops! Ainda não temos usuários para exibir'
    }
  }
}
