import { BaseCard } from '@positivote/design-system/components/BaseCard'
import { Button } from '@positivote/design-system/components/Button'
import { Div } from '@positivote/design-system/components/Div'
import { Divider } from '@positivote/design-system/components/Divider'
import { Grid } from '@positivote/design-system/components/Grid'
import { IconButton } from '@positivote/design-system/components/IconButton'
import { IconWrapper } from '@positivote/design-system/components/IconWrapper'
import { LI } from '@positivote/design-system/components/LI'
import { Main } from '@positivote/design-system/components/Main'
import { Pagination } from '@positivote/design-system/components/Pagination'
import { Typography } from '@positivote/design-system/components/Typography'
import { UL } from '@positivote/design-system/components/UL'
import { FormContainer } from '@positivote/design-system/components-form/Container'
import { FormSelect } from '@positivote/design-system/components-form/Select'
import { useTheme } from '@positivote/design-system/hooks'
import { AlarmIcon } from '@positivote/design-system/icons/Alarm'
import { KeyIcon } from '@positivote/design-system/icons/Key'
import { PersonIcon } from '@positivote/design-system/icons/Person'
import { QuickAccessIcon } from '@positivote/design-system/icons/QuickAccess'
import { VerifiedIcon } from '@positivote/design-system/icons/Verified'
import { VisibilityIcon } from '@positivote/design-system/icons/Visibility'
import { VisibilityOffIcon } from '@positivote/design-system/icons/VisibilityOff'
import { Breakpoint } from '@positivote/design-system/theme'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'

import { AppBar } from '@/common/components/AppBar'
import { EmptyList } from '@/common/components/EmptyList'
import { WrappedLoader } from '@/common/components/WrappedLoader'
import {
  DEFAULT_BREAK_POINT_PER_PAGE,
  XL_BREAK_POINT_PER_PAGE
} from '@/common/constants/react-query'
import { changePageTitle } from '@/common/helpers'
import { useCountdown } from '@/common/hooks/countdown'
import { i18n } from '@/common/i18n'
import { QuickAccessForm } from '@/modules/hub/quick-access/contracts/form'
import {
  useClearQuickAccess,
  useCreateQuickAccess,
  useRevokeQuickAccess,
  useShowQuickAccess
} from '@/modules/hub/quick-access/hooks'
import { ListSchoolClassStudentHookParams } from '@/modules/hub/school-classes/contracts/hooks'
import { useListSchoolClassStudent } from '@/modules/hub/school-classes/hooks'

export function FormQuickAccess(): JSX.Element {
  changePageTitle(i18n().modules.hub.quickAccess.pages.form.pageTitle)
  const navigate = useNavigate()
  const params = useParams()
  const { breakpoint } = useTheme()

  const [userIdToShowLogin, setUserIdToShowLogin] = useState<string | null>(null)
  const [listSchoolClassStudentsParams, setListSchoolClassStudentsParams] = useState<
    ListSchoolClassStudentHookParams['model']
  >({
    page: 1,
    perPage: breakpoint === Breakpoint.xl ? XL_BREAK_POINT_PER_PAGE : DEFAULT_BREAK_POINT_PER_PAGE,
    classId: params.id ?? ''
  })
  const createQuickAccess = useCreateQuickAccess()
  const revokeQuickAccess = useRevokeQuickAccess()
  const clearQuickAccess = useClearQuickAccess()
  const showQuickAccess = useShowQuickAccess({
    model: {
      classId: Number(params.id)
    },
    queryOptions: {
      enabled: !!params.id
    }
  })
  const listSchoolClassStudents = useListSchoolClassStudent({
    model: listSchoolClassStudentsParams,
    queryOptions: {
      refetchInterval: 30000,
      enabled: !!params.id
    }
  })

  const countdown = useCountdown({
    expiresAt: showQuickAccess.data?.expiresAtTimeFormatted,
    onTimeExpire: () => {
      onClearQuickAccess()
    }
  })

  const selectOptionsClassLevel = [
    {
      key: 60,
      label: i18n().modules.hub.quickAccess.pages.form.input.selectOptionsClassLevel.oneHours
    },
    {
      key: 120,
      label: i18n().modules.hub.quickAccess.pages.form.input.selectOptionsClassLevel.twoHours
    }
  ]

  const { control, handleSubmit } = useForm<QuickAccessForm>({
    defaultValues: { duration: 60 },
    mode: 'onSubmit'
  })

  function onSubmit(data: QuickAccessForm): void {
    createQuickAccess.mutate({
      model: {
        classId: Number(params.id),
        duration: data.duration
      }
    })
  }

  function onRevoke(): void {
    if (showQuickAccess.data?.id) {
      revokeQuickAccess.mutate({
        model: {
          quickAccessId: showQuickAccess.data.id,
          classId: Number(params.id)
        }
      })
    }
  }

  function onClearQuickAccess(): void {
    clearQuickAccess.mutate({
      model: {
        classId: Number(params.id)
      }
    })
  }

  return (
    <Main css={{ display: 'flex', flexDirection: 'column', flex: 1, overflowX: 'hidden' }}>
      <AppBar
        title={i18n().modules.hub.quickAccess.pages.form.appBar.title}
        goBackFunction={() => navigate(-1)}
        breadcrumbItems={[
          {
            label: i18n().modules.hub.quickAccess.pages.form.appBar.breadcrumbs.overview,
            onClick: () => navigate(-1)
          },
          {
            label: i18n().modules.hub.quickAccess.pages.form.appBar.breadcrumbs.class(
              listSchoolClassStudents.data?.schoolClass
            )
          }
        ]}
      />
      {showQuickAccess.isFetching ? (
        <WrappedLoader data-testid="Loader-Container-FormQuickAccess" />
      ) : (
        <Div
          css={{
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
            padding: '$lg',
            overflowY: 'auto',
            gap: '$lg',
            '@sm': { padding: '$md' }
          }}
        >
          <Div css={{ display: 'flex', flexDirection: 'column', gap: '$md' }}>
            <Div css={{ display: 'flex', gap: '$2xs' }}>
              <QuickAccessIcon fill="$on-surface-variant" data-testid="QuickAccessIcon" />
              <Typography
                data-testid="Typography-accessCode"
                variant="titleMedium"
                css={{ color: '$on-surface' }}
              >
                {i18n().modules.hub.quickAccess.pages.form.accessCode}
              </Typography>
            </Div>
            {showQuickAccess.data ? (
              <Div css={{ display: 'flex', alignItems: 'center', gap: '$lg' }}>
                <Div css={{ display: 'flex', gap: '$4xs', alignItems: 'center' }}>
                  <AlarmIcon size={18} fill="$on-surface-variant" data-testid="AlarmIcon" />
                  <Typography
                    data-testid="Typography-timeLeft"
                    variant="bodyMedium"
                    css={{ color: '$on-surface-variant' }}
                  >
                    {i18n().modules.hub.quickAccess.pages.form.timeLeft}
                  </Typography>

                  <Typography
                    data-testid="Typography-expiresAtTimeFormatted"
                    variant="titleMedium"
                    css={{ color: '$primary', marginLeft: '$4xs' }}
                  >
                    {countdown}
                  </Typography>
                </Div>
                <Div
                  css={{
                    display: 'flex',
                    backgroundColor: '$primary-container',
                    borderRadius: '$md',
                    padding: '$2xs $md',
                    gap: '$2xs'
                  }}
                >
                  <KeyIcon fill="$on-primary-container" data-testid="KeyIcon" />
                  <Typography
                    data-testid="Typography-code"
                    variant="titleLarge"
                    css={{ color: '$on-primary-container' }}
                  >
                    {showQuickAccess.data.code}
                  </Typography>
                </Div>
                <Button
                  data-testid="cancelCode"
                  onClick={onRevoke}
                  isLoading={revokeQuickAccess.isPending}
                  variant="outlined"
                >
                  {i18n().modules.hub.quickAccess.pages.form.cancelCode}
                </Button>
              </Div>
            ) : (
              <FormContainer
                formHandleSubmit={handleSubmit}
                onSubmit={onSubmit}
                css={{ display: 'flex', gap: '$lg', alignItems: 'center' }}
              >
                <FormSelect
                  name="duration"
                  gridProps={{ xl: 2 }}
                  control={control}
                  label={i18n().modules.hub.quickAccess.pages.form.input.duration}
                  data-testid="classLevel"
                  optionKeyField="key"
                  optionTitleField="label"
                  options={selectOptionsClassLevel}
                  variant="outlined"
                />
                <Button
                  data-testid="generateCode"
                  type="submit"
                  isLoading={createQuickAccess.isPending}
                >
                  {i18n().modules.hub.quickAccess.pages.form.generateCode}
                </Button>
              </FormContainer>
            )}
          </Div>
          <Divider css={{ minWidth: 1, minHeight: 1 }} />
          {listSchoolClassStudents.isFetching ? (
            <WrappedLoader data-testid="Loader-Container-FormQuickAccess" />
          ) : !listSchoolClassStudents.data?.registers.length ? (
            <EmptyList title={i18n().modules.hub.quickAccess.pages.form.emptyList} />
          ) : (
            <Div>
              <Grid spacing="$md" css={{ padding: '$md $lg', marginBottom: '$2xs' }}>
                <Grid xl={3} md={4} sm={5}>
                  <Typography
                    variant="titleMedium"
                    lineClamp={1}
                    css={{ color: '$on-surface' }}
                    data-testid="Typography-headerAccessUser"
                  >
                    {i18n().modules.hub.quickAccess.pages.form.accessUser}
                  </Typography>
                </Grid>
                <Grid xl={9} md={4} sm={5}>
                  <Typography
                    variant="titleMedium"
                    lineClamp={1}
                    css={{ color: '$on-surface' }}
                    data-testid="Typography-headerAccessCode"
                  >
                    {i18n().modules.hub.quickAccess.pages.form.accessCode}
                  </Typography>
                </Grid>
              </Grid>
              <UL
                css={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '$2xs'
                }}
              >
                {listSchoolClassStudents.data.registers.map((student, index) => (
                  <BaseCard
                    key={student.id}
                    data-testid={`BaseCard-Container-studentListItem-${index}`}
                    component={LI}
                    css={{
                      '& .BaseCard-StateLayer': {
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'center',
                        height: 'min-content',
                        padding: '$md $lg'
                      }
                    }}
                  >
                    <Grid spacing="$md" css={{ display: 'flex', alignItems: 'center' }}>
                      <Grid
                        xl={3}
                        md={4}
                        sm={5}
                        css={{ display: 'flex', gap: '$md', alignItems: 'center' }}
                      >
                        <IconWrapper size={32} css={{ backgroundColor: '$primary-container' }}>
                          <PersonIcon size={19} fill="$on-primary-container" />
                        </IconWrapper>
                        <Typography
                          variant="bodyMedium"
                          lineClamp={1}
                          css={{ color: '$on-surface' }}
                          data-testid={`Typography-studentName-${index}`}
                        >
                          {student.name}
                        </Typography>
                      </Grid>
                      <Grid
                        xl={7}
                        md={4}
                        sm={5}
                        css={{
                          display: 'flex',
                          gap: '$md',
                          alignItems: 'center'
                        }}
                      >
                        {userIdToShowLogin === student.id ? (
                          <>
                            <IconButton
                              size={32}
                              data-testid={`VisibilityIcon-${index}`}
                              variant="standard"
                              onClick={() => setUserIdToShowLogin(null)}
                            >
                              <VisibilityIcon data-testid={`VisibilityIcon-${index}`} />
                            </IconButton>
                            <Typography
                              variant="bodyMedium"
                              lineClamp={1}
                              css={{ color: '$on-surface' }}
                              data-testid={`Typography-studentLogin-${index}`}
                            >
                              {student.login}
                            </Typography>
                          </>
                        ) : (
                          <>
                            <IconButton
                              size={32}
                              data-testid={`VisibilityOffIcon-${index}`}
                              variant="standard"
                              onClick={() => setUserIdToShowLogin(student.id)}
                            >
                              <VisibilityOffIcon data-testid={`VisibilityOffIcon-${index}`} />
                            </IconButton>
                            <Typography
                              variant="bodyMedium"
                              lineClamp={1}
                              css={{ color: '$on-surface' }}
                              data-testid={`Typography-studentLoginOff-${index}`}
                            >
                              {i18n().modules.hub.quickAccess.pages.form.visibilityOff}
                            </Typography>
                          </>
                        )}
                      </Grid>

                      <Grid
                        xl={2}
                        md={4}
                        sm={5}
                        css={{
                          display: 'flex',
                          gap: '$md',
                          alignItems: 'center',
                          justifyContent: 'flex-end'
                        }}
                      >
                        <Div
                          css={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '$2xs',
                            minWidth: 'fit-content',
                            opacity: student.online ? '$default' : '$transparent'
                          }}
                        >
                          <VerifiedIcon fill="$success" data-testid={`VerifiedIcon-${index}`} />
                          <Typography
                            data-testid={`Typography-online-${index}`}
                            variant="bodyMedium"
                            lineClamp={1}
                            css={{ color: '$success' }}
                          >
                            {i18n().modules.hub.quickAccess.pages.form.online}
                          </Typography>
                        </Div>
                      </Grid>
                    </Grid>
                  </BaseCard>
                ))}
              </UL>
              {listSchoolClassStudents.data.lastPage > 1 && (
                <Pagination
                  css={{ marginTop: '$lg' }}
                  lastPage={listSchoolClassStudents.data.lastPage}
                  page={listSchoolClassStudents.data.page}
                  setPage={(page) =>
                    setListSchoolClassStudentsParams((oldState) => ({ ...oldState, page }))
                  }
                />
              )}
            </Div>
          )}
        </Div>
      )}
    </Main>
  )
}
