import axios, { AxiosError, AxiosResponse } from 'axios'

import { ApiServiceParams, ApiServiceResult } from '@/common/contracts'
import { ApplicationException, UnauthorizedException } from '@/common/exceptions'
import { getParsedException, isTokenOverdue } from '@/common/helpers'
import { i18n } from '@/common/i18n'
import { CreateSessionApiToken } from '@/modules/hub/auth/contracts/apis'
import { Session, Token } from '@/modules/hub/auth/contracts/models'
import { getTokenRepository, saveTokenRepository } from '@/modules/hub/auth/repositories'

const axiosClient = axios.create({
  baseURL: import.meta.env.VITE_HUB_API_URL,
  timeout: 20000
})

let isRefreshing = false
let defaultToken: Token | undefined

const requestQueue: Array<{
  call: () => Promise<any>
  resolve: (value: any) => void
  reject: (error: any) => void
}> = []

async function callApi<RequestData, ResponseData>({
  resource,
  method,
  url,
  baseURL,
  params,
  body,
  headers
}: Omit<ApiServiceParams<RequestData>, 'ignoreRefreshing'>): Promise<
  ApiServiceResult<ResponseData>
> {
  return axiosClient
    .request<ApiServiceParams<RequestData>['body'], AxiosResponse<ResponseData>>({
      method,
      url,
      baseURL,
      params,
      data: body,
      headers: {
        ...axiosClient.defaults.headers,
        ...headers
      } as Record<string, string>
    })
    .then((res) => ({ data: res.data, headers: res.headers as Record<string, string> }))
    .catch((error: AxiosError<ApplicationException>) => {
      const parsedError = (error.response?.data ?? error) as ApplicationException
      parsedError.statusCode = error.response?.status ?? 500
      throw getParsedException(resource, parsedError)
    })
}

async function makeRefreshToken(): Promise<Token> {
  isRefreshing = true
  const refreshToken = getTokenRepository()?.refreshToken
  if (!refreshToken) {
    consumeHubQueue(true)
    throw new UnauthorizedException()
  }
  const { data } = await callApi<
    { RefreshToken: string },
    Omit<CreateSessionApiToken, 'RefreshToken'>
  >({
    resource: i18n().common.services.hubApi.makeRefreshToken,
    method: 'post',
    url: '/login/v1/auth/refresh-token',
    body: { RefreshToken: refreshToken }
  }).catch(() => {
    consumeHubQueue(true)
    throw new UnauthorizedException()
  })

  const token: Token = {
    tokenType: data.TokenType,
    accessToken: data.AccessToken,
    idToken: data.IdToken,
    refreshToken,
    expiresIn: data.ExpiresIn
  }
  setHubApiClientToken(token)
  saveTokenRepository(token)
  consumeHubQueue()
  return token
}

export function consumeHubQueue(rejectAll = false): void {
  isRefreshing = false
  requestQueue.forEach(({ call, resolve, reject }) => {
    if (rejectAll) {
      reject(new UnauthorizedException())
    } else {
      call().then(resolve).catch(reject)
    }
  })
}

export function setHubApiClientSession({ profileId, orgId, userId }: Session): void {
  axiosClient.defaults.headers['Hub-Identity'] = profileId
  axiosClient.defaults.headers['Id-Org'] = orgId
  axiosClient.defaults.headers['Org-Id'] = orgId
  axiosClient.defaults.headers['X-Relationship-Id'] = userId
}

export function removeHubApiClientSession(): void {
  defaultToken = undefined
  axiosClient.defaults.headers.authorization = null
  axiosClient.defaults.headers['Hub-Identity'] = null
  axiosClient.defaults.headers['Id-Org'] = null
  axiosClient.defaults.headers['Org-Id'] = null
  axiosClient.defaults.headers['X-Relationship-Id'] = null
}

export function setHubApiClientToken(token: Token): void {
  defaultToken = token
}

export function getHubApiClientToken(): Token | undefined {
  return defaultToken
}

export async function hubApiService<RequestData, ResponseData>({
  ignoreRefreshing,
  tokenType = 'accessToken',
  ...params
}: ApiServiceParams<RequestData> & {
  tokenType?: 'accessToken' | 'idToken'
}): Promise<ApiServiceResult<ResponseData>> {
  if (defaultToken?.[tokenType] && isTokenOverdue(defaultToken[tokenType]) && !isRefreshing) {
    await makeRefreshToken()
  }
  const call = async (): Promise<ApiServiceResult<ResponseData>> =>
    callApi({ ...params, headers: { ...params.headers, authorization: defaultToken?.[tokenType] } })
  if (isRefreshing && !ignoreRefreshing) {
    return new Promise((resolve, reject) => {
      requestQueue.push({ call, resolve, reject })
    })
  }
  return call()
}
