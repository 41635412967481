import { Button } from '@positivote/design-system/components/Button'
import { AddCircleIcon } from '@positivote/design-system/icons/AddCircle'
import { useNavigate } from 'react-router-dom'

import { i18n } from '@/common/i18n'

export function ButtonCreateSchoolYear(): JSX.Element {
  const navigate = useNavigate()
  return (
    <Button
      onClick={() => navigate('/data-management/levels-and-classes/school-year/form')}
      variant="tonal"
      LeadingIcon={<AddCircleIcon size={18} />}
      css={{ minWidth: 206 }}
    >
      {i18n().modules.hub.schoolYear.pages.list.appBar.buttonTitle}
    </Button>
  )
}
