import { Div } from '@positivote/design-system/components/Div'
import { Main } from '@positivote/design-system/components/Main'
import { StepProps, Stepper } from '@positivote/design-system/components/Stepper'
import { Breakpoint } from '@positivote/design-system/theme'
import { useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { AppBar } from '@/common/components/AppBar'
import { i18n } from '@/common/i18n'
import { useAuth } from '@/modules/hub/auth/contexts'
import { OrganizationKind } from '@/modules/hub/organizations/contracts'
import {
  SchoolYearStepForm,
  SchoolYearStepperState
} from '@/modules/hub/school-year/contracts/form'

import { useShowSchoolYear } from '../../hooks'

import { SchoolYearDataStep } from './Data'
import { SchoolYearListSchoolStep } from './ListSchool'
import { SchoolYearPreviewStep } from './Preview'

export function FormSchoolYear(): JSX.Element {
  const navigate = useNavigate()
  const params = useParams()
  const { profile } = useAuth()
  const [isLoading, setIsLoading] = useState(false)
  const [stepperState, setStepperState] = useState<SchoolYearStepperState>({
    selectedSchool: {
      form: null
    },
    schoolYearData: {
      form: null,
      hasError: false,
      isValid: false,
      canGoNext: true
    },
    preview: { form: null, canGoNext: true }
  })
  const isSchool = profile?.organization.kindId === OrganizationKind.SCHOOL
  const [currentStepPosition, setCurrentStepPosition] = useState(
    isSchool && params.id ? 1 : params.id ? 2 : 1
  )

  const stepperRef = useRef<{
    validateDataForm: () => Promise<SchoolYearStepForm | null>
    handleCancel: () => void
    onSubmit: (setIsLoading: (isLoading: boolean) => void) => void
  }>(null)

  async function onClickNext(newStep: StepProps): Promise<void> {
    const serviceDataStepFormData = await stepperRef.current?.validateDataForm()
    if (!serviceDataStepFormData) {
      return
    }
    setStepperState((oldState) => ({
      ...oldState,
      schoolYearData: {
        ...oldState.schoolYearData,
        form: {
          ...serviceDataStepFormData
        },
        selectedSchool: {
          schoolName: stepperState.selectedSchool.form?.schoolName,
          idSchool: stepperState.selectedSchool.form?.idSchool
        }
      }
    }))
    setCurrentStepPosition(newStep.position)
  }

  const showSchoolYear = useShowSchoolYear({
    model: {
      termId: params.id!
    },
    queryOptions: {
      enabled: !!params.id
    },

    onError: () => {
      navigate('/data-management/levels-and-classes/school-year')
    }
  })

  const schoolStepProps: StepProps[] = [
    {
      position: 1,
      title: i18n().modules.hub.schoolYear.pages.form.stepper.schoolYearData.stepTitle,
      status: stepperState.schoolYearData.hasError ? 'error' : 'active',
      canGoNext: !stepperState.schoolYearData.hasError,
      showPreviousButton: true,
      children: (
        <SchoolYearDataStep
          isLoading={showSchoolYear.isLoading}
          ref={stepperRef}
          stepperState={stepperState}
          setStepState={(stepState) =>
            setStepperState((oldState) => ({
              ...oldState,
              schoolYearData: { ...oldState.schoolYearData, ...stepState }
            }))
          }
        />
      )
    },
    {
      position: 2,
      title: i18n().modules.hub.schoolYear.pages.form.stepper.preview.stepTitle,
      status: 'active',
      canGoNext: stepperState.preview.canGoNext,
      children: (
        <SchoolYearPreviewStep
          ref={stepperRef}
          stepperState={stepperState}
          setStepState={(stepState) =>
            setStepperState((oldState) => ({
              ...oldState,
              preview: { ...oldState.preview, ...stepState }
            }))
          }
        />
      )
    }
  ]
  const networkingStepProps: StepProps[] = [
    {
      position: 1,
      title: i18n().modules.hub.schoolYear.pages.form.stepper.listSchool.stepTitle,
      status: 'active',
      canGoNext: stepperState.schoolYearData.hasError,
      showFooter: false,
      children: (
        <SchoolYearListSchoolStep
          setCurrentStepPosition={setCurrentStepPosition}
          stepState={stepperState.selectedSchool}
          setStepState={(stepState) =>
            setStepperState((oldState) => ({
              ...oldState,
              selectedSchool: { ...oldState.selectedSchool, ...stepState }
            }))
          }
        />
      )
    },
    {
      position: 2,
      title: i18n().modules.hub.schoolYear.pages.form.stepper.schoolYearData.stepTitle,
      status: 'active',
      canGoNext: !stepperState.schoolYearData.hasError,
      showPreviousButton: !params.id,

      children: (
        <SchoolYearDataStep
          isLoading={showSchoolYear.isLoading}
          ref={stepperRef}
          stepperState={stepperState}
          setStepState={(stepState) =>
            setStepperState((oldState) => ({
              ...oldState,
              schoolYearData: { ...oldState.schoolYearData, ...stepState }
            }))
          }
        />
      )
    },
    {
      position: 3,
      title: i18n().modules.hub.schoolYear.pages.form.stepper.preview.stepTitle,
      status: 'active',
      canGoNext: true,
      children: (
        <SchoolYearPreviewStep
          ref={stepperRef}
          stepperState={stepperState}
          setStepState={(stepState) =>
            setStepperState((oldState) => ({
              ...oldState,
              preview: { ...oldState.preview, ...stepState }
            }))
          }
        />
      )
    }
  ]

  const currentStepProps = isSchool ? schoolStepProps : networkingStepProps

  function handleCancel(): void {
    stepperRef.current?.handleCancel()
  }

  useEffect(() => {
    if (showSchoolYear.data) {
      setStepperState({
        selectedSchool: {
          form: {
            idSchool: showSchoolYear.data.institutionId,
            schoolName: showSchoolYear.data.schoolName
          }
        },
        schoolYearData: {
          form: {
            name: showSchoolYear.data.title,
            initialDate: showSchoolYear.data.startDate,
            finalDate: showSchoolYear.data.endDate,
            isCopy: undefined,
            schoolYear: undefined,
            teacher: undefined
          },
          canGoNext: true,
          hasError: false,
          isValid: true
        },
        preview: {
          form: {
            status: showSchoolYear.data.status
          },
          canGoNext: true
        }
      })
    }
  }, [showSchoolYear.data])

  return (
    <Main
      css={{
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        overflowX: 'hidden'
      }}
    >
      <AppBar
        title={i18n().modules.hub.dataManagement.pages.menu.appBar.title}
        goBackFunction={() => navigate(-1)}
        breadcrumbItems={[
          {
            label: i18n().modules.hub.schoolYear.pages.list.appBar.breadcrumbs.overview,
            onClick: () => {
              navigate(-3)
            }
          },
          {
            label:
              i18n().modules.hub.schoolYear.pages.list.appBar.breadcrumbs.educationLevelsAndClasses,
            onClick: () => {
              navigate(-2)
            }
          },
          {
            label: i18n().modules.hub.schoolYear.pages.list.appBar.breadcrumbs.schoolYear,
            onClick: () => {
              navigate(-1)
            }
          },
          {
            label: i18n().modules.hub.schoolYear.pages.form.stepper.schoolYearData.newSchoolYear
          }
        ]}
      />
      <Div
        css={{
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
          overflowY: 'auto',

          '@sm': { padding: '$md' }
        }}
      >
        <Stepper
          orientation="horizontal"
          position="start"
          stepOrientation="horizontal"
          isLoading={isLoading}
          nextButtonProps={{ onClick: onClickNext }}
          previousButtonProps={{
            onClick: (newStep) => setCurrentStepPosition(newStep.position)
          }}
          doneButtonProps={{
            label: i18n().modules.hub.schoolYear.pages.form.stepper.schoolYearData.saveSchoolYear,
            onClick: () => stepperRef.current?.onSubmit(setIsLoading)
          }}
          cancelButtonProps={{
            onClick: () => handleCancel()
          }}
          mobileProps={{ breakpoint: Breakpoint.sm, progressType: 'dots' }}
          currentStepPosition={currentStepPosition}
          steps={currentStepProps}
          css={{
            flex: 1,
            padding: '$lg',
            '& .StepperHeader-Container': { width: 670, padding: '$none' },
            '& .Stepper-ChildrenWrapper': {
              display: 'flex',
              flex: 1
            }
          }}
        />
      </Div>
    </Main>
  )
}
