import { useAlert } from '@positivote/design-system/hooks'
import {
  UseMutationResult,
  UseQueryResult,
  keepPreviousData,
  useMutation,
  useQuery,
  useQueryClient
} from '@tanstack/react-query'

import { ApplicationException } from '@/common/exceptions'
import { useErrorHandler } from '@/common/hooks'
import { i18n } from '@/common/i18n'
import { useAuth } from '@/modules/hub/auth/contexts'

import {
  ListProfileHookParams,
  ListProfileHookResult,
  ListProfileRoleHookParams,
  ListProfileRoleHookResult,
  UpdateProfileConsentHookParams,
  UpdateProfileHookParams,
  UpdateProfileHookResult
} from './contracts/hooks'
import { profileDetailsFormatSanitizer } from './sanitizers'
import {
  listMyProfileService,
  listProfileRolesService,
  listProfileService,
  updateProfileConsentService,
  updateProfileService
} from './services'

export const hookKey = 'profiles'

export function useListProfile({
  model,
  ignoreLogout,
  queryOptions,
  onSuccess,
  onError
}: ListProfileHookParams): UseQueryResult<ListProfileHookResult, ApplicationException> {
  const { handleError } = useErrorHandler({ ignoreLogout })

  return useQuery({
    queryKey: [hookKey, 'list', model],
    placeholderData: keepPreviousData,
    queryFn: async () => {
      try {
        const listProfiles = await listProfileService(model)
        onSuccess?.(listProfiles)
        return listProfiles
      } catch (error) {
        const parsedError = error as ApplicationException
        handleError({ error: parsedError })
        onError?.({ error: parsedError })
        throw parsedError
      }
    },
    ...queryOptions
  })
}

export function useListMyProfile({
  model,
  ignoreLogout,
  queryOptions,
  onSuccess,
  onError
}: ListProfileHookParams): UseQueryResult<ListProfileHookResult, ApplicationException> {
  const { handleError } = useErrorHandler({ ignoreLogout })

  return useQuery({
    queryKey: [hookKey, 'list', model],
    placeholderData: keepPreviousData,
    queryFn: async () => {
      try {
        const listMyProfiles = await listMyProfileService(model)
        onSuccess?.(listMyProfiles)
        return listMyProfiles
      } catch (error) {
        const parsedError = error as ApplicationException
        handleError({ error: parsedError })
        onError?.({ error: parsedError })
        throw parsedError
      }
    },
    ...queryOptions
  })
}

export function useListProfileAndSetCache(): UseMutationResult<
  ListProfileHookResult,
  ApplicationException,
  ListProfileHookParams
> {
  const { handleError } = useErrorHandler()
  const queryClient = useQueryClient()

  return useMutation({
    mutationKey: [hookKey, 'listAndSetCache'],
    mutationFn: async ({ model, onSuccess, onError }: ListProfileHookParams) => {
      try {
        const listProfiles = await listProfileService(model)
        queryClient.setQueryData([hookKey, 'list', model], listProfiles)
        onSuccess?.(listProfiles)
        return listProfiles
      } catch (error) {
        const parsedError = error as ApplicationException
        handleError({ error: parsedError })
        onError?.({ error: parsedError })
        throw parsedError
      }
    }
  })
}

export function useUpdateProfile(): UseMutationResult<
  UpdateProfileHookResult,
  ApplicationException,
  UpdateProfileHookParams
> {
  const { handleError } = useErrorHandler()
  const { addAlertMessage } = useAlert()
  const { saveProfile } = useAuth()

  return useMutation({
    mutationKey: [hookKey, 'update'],
    mutationFn: async ({ model, onSuccess, onError }: UpdateProfileHookParams) => {
      try {
        await updateProfileService(model)
        const profileSanitized = profileDetailsFormatSanitizer(model)
        saveProfile(profileSanitized)
        onSuccess?.(profileSanitized)
        addAlertMessage({
          alertKey: 'profileUpdated',
          subTitle: i18n().modules.hub.profiles.pages.form.alert.updated,
          severity: 'success'
        })
        return profileSanitized
      } catch (error) {
        const parsedError = error as ApplicationException
        onError?.({ error: parsedError })
        handleError({ error: parsedError })
        throw parsedError
      }
    }
  })
}

export function useListProfileRoles({
  queryOptions,
  onSuccess,
  onError
}: ListProfileRoleHookParams): UseQueryResult<ListProfileRoleHookResult, ApplicationException> {
  const { handleError } = useErrorHandler()

  return useQuery({
    queryKey: [hookKey, 'listRoles'],
    placeholderData: keepPreviousData,
    queryFn: async () => {
      try {
        const listRoles = await listProfileRolesService()
        onSuccess?.(listRoles)
        return listRoles
      } catch (error) {
        const parsedError = error as ApplicationException
        handleError({ error: parsedError })
        onError?.({ error: parsedError })
        throw parsedError
      }
    },
    ...queryOptions
  })
}

export function useUpdateProfileConsent(): UseMutationResult<
  void,
  ApplicationException,
  UpdateProfileConsentHookParams
> {
  const { handleError } = useErrorHandler()
  const queryClient = useQueryClient()
  return useMutation({
    mutationKey: [hookKey, 'updateConsent'],
    mutationFn: async ({ model, onSuccess, onError }: UpdateProfileConsentHookParams) => {
      try {
        await updateProfileConsentService(model)
        queryClient.setQueryData(
          [
            hookKey,
            'list',
            {
              byAccount: model.byAccount,
              orgId: model.orgId,
              page: model.page,
              perPage: model.perPage,
              search: model.search,
              roleCode: model.roleCode
            }
          ],
          (oldProfiles: ListProfileHookResult | undefined) =>
            oldProfiles && {
              ...oldProfiles,
              registers: oldProfiles.registers.map((profile) =>
                profile.id === model.profileId
                  ? { ...profile, allowedImageUseOnEdtech: model.consent }
                  : profile
              )
            }
        )
        onSuccess?.()
      } catch (error) {
        const parsedError = error as ApplicationException
        handleError({ error: parsedError })
        onError?.({ error: parsedError })
        throw parsedError
      }
    }
  })
}
