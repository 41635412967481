import { yupResolver } from '@hookform/resolvers/yup'
import { BaseCard } from '@positivote/design-system/components/BaseCard'
import { Button } from '@positivote/design-system/components/Button'
import { Div } from '@positivote/design-system/components/Div'
import { Grid } from '@positivote/design-system/components/Grid'
import { IconButton } from '@positivote/design-system/components/IconButton'
import { IconWrapper } from '@positivote/design-system/components/IconWrapper'
import { Image } from '@positivote/design-system/components/Image'
import { Main } from '@positivote/design-system/components/Main'
import { TextField } from '@positivote/design-system/components/TextField'
import { Typography } from '@positivote/design-system/components/Typography'
import { FormCheckbox } from '@positivote/design-system/components-form/Checkbox'
import { FormContainer } from '@positivote/design-system/components-form/Container'
import { FormFileInput } from '@positivote/design-system/components-form/FileInput'
import { FormSelect } from '@positivote/design-system/components-form/Select'
import { FormTextField } from '@positivote/design-system/components-form/TextField'
import { useTheme } from '@positivote/design-system/hooks'
import { CancelIcon } from '@positivote/design-system/icons/Cancel'
import { CheckCircleIcon } from '@positivote/design-system/icons/CheckCircle'
import { EditIcon } from '@positivote/design-system/icons/Edit'
import { PersonIcon } from '@positivote/design-system/icons/Person'
import { Breakpoint } from '@positivote/design-system/theme'
import { useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { v4 as uuidV4 } from 'uuid'

import { AppBar } from '@/common/components/AppBar'
import { WrappedLoader } from '@/common/components/WrappedLoader'
import {
  cellMask,
  changePageTitle,
  cpfMask,
  defaultDateMask,
  genderDictionary,
  validateForm
} from '@/common/helpers'
import { i18n } from '@/common/i18n'
import { Footer } from '@/common/layouts/Footer'
import { useAuth } from '@/modules/hub/auth/contexts'
import {
  AttentionDialog,
  AttentionDialogProps
} from '@/modules/hub/profiles/components/AttentionPointDialog'
import { UpdateProfileForm } from '@/modules/hub/profiles/contracts'
import { useUpdateProfile } from '@/modules/hub/profiles/hooks'
import {
  profileToFormSanitizer,
  updateProfileSchemaSanitizer
} from '@/modules/hub/profiles/sanitizers'
import {
  makeUpdateProfileValidationSchema,
  makeUpdateStudentProfileValidationSchema
} from '@/modules/hub/profiles/validations'
import { usePermissionRole } from '@/modules/hub/security-configuration/hooks'

export function ProfileForm(): JSX.Element {
  changePageTitle(i18n().modules.hub.profiles.pages.form.pageTitle)

  const [selectedImage, setSelectedImage] = useState<string | null>(null)
  const [fileSizeError, setFileSizeError] = useState('')
  const [errorFields, setErrorFields] = useState<AttentionDialogProps['errorFields']>([])
  const fileInputId = useMemo(() => uuidV4(), [])

  const navigate = useNavigate()
  const { breakpoint } = useTheme()
  const { profile } = useAuth()
  const { checkPermissionRole, isLoading, disabledFields } = usePermissionRole()
  const updateProfile = useUpdateProfile()

  const genderOptions = Object.values(genderDictionary)

  const {
    register,
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { errors }
  } = useForm<UpdateProfileForm>({
    mode: 'onSubmit',
    resolver: async (values, ...args) =>
      yupResolver<UpdateProfileForm>(
        profile?.role.code === 'aluno'
          ? makeUpdateStudentProfileValidationSchema(disabledFields)
          : makeUpdateProfileValidationSchema(disabledFields),
        { strict: true }
      )(updateProfileSchemaSanitizer(values), ...args)
  })

  function onSubmit(data: UpdateProfileForm): void {
    if (!profile) {
      return
    }

    const validationSchema =
      profile.role.code === 'aluno'
        ? makeUpdateStudentProfileValidationSchema([])
        : makeUpdateProfileValidationSchema([])

    const { isValid, errorFields: newErrorFields } = validateForm(validationSchema, data)

    if (!isValid) {
      setErrorFields(newErrorFields)
      updateProfile.mutate({
        model: { ...profile, ...data }
      })
      return
    }

    updateProfile.mutate({
      model: { ...profile, ...data },
      onSuccess: () => navigate(-1)
    })
  }

  useEffect(() => {
    if (profile) {
      reset(profileToFormSanitizer(profile))
    }
  }, [reset, profile])

  return (
    <Main css={{ display: 'flex', flexDirection: 'column', flex: 1, overflowX: 'hidden' }}>
      <AttentionDialog
        errorFields={errorFields}
        acceptAction={() => navigate(-1)}
        onCancel={() => {
          setErrorFields([])
        }}
        data-testid="attention"
      />
      <AppBar
        title={i18n().modules.hub.profiles.pages.form.appBar.title}
        breadcrumbItems={[
          {
            label: i18n().modules.hub.profiles.pages.form.appBar.breadcrumbs.myData,
            onClick: () => navigate(-1)
          },
          {
            label: i18n().modules.hub.profiles.pages.form.appBar.breadcrumbs.editMyData
          }
        ]}
        goBackFunction={() => navigate(-1)}
      />

      <Div css={{ display: 'flex', flexDirection: 'column', flex: 1, overflowY: 'auto' }}>
        <Div css={{ display: 'flex', flex: 1, padding: '$lg', '@sm': { padding: '$md' } }}>
          {!profile || isLoading ? (
            <WrappedLoader />
          ) : (
            <Grid xl={8} sm={12}>
              <BaseCard css={{ '& .BaseCard-StateLayer': { padding: '$lg', gap: '$lg' } }}>
                <Div
                  css={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
                >
                  <Typography variant="titleLarge" css={{ color: '$on-surface' }}>
                    {i18n().modules.hub.profiles.pages.form.editMyData}
                  </Typography>
                </Div>
                <Div
                  css={{
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    width: '100%',
                    alignItems: 'center',
                    gap: '$md'
                  }}
                >
                  <Div css={{ display: 'flex', position: 'relative' }}>
                    {selectedImage ?? profile.picture ? (
                      <Image
                        alt={i18n().modules.hub.profiles.pages.form.pictureAlt}
                        FallbackImage={() => (
                          <IconWrapper size={160} css={{ backgroundColor: '$primary-container' }}>
                            <PersonIcon size={96} fill="$on-primary-container" />
                          </IconWrapper>
                        )}
                        css={{
                          borderRadius: '$full',
                          overflow: 'hidden',
                          objectFit: 'cover',
                          width: 160,
                          height: 160
                        }}
                        src={selectedImage ?? profile.picture}
                      />
                    ) : (
                      <IconWrapper size={160} css={{ backgroundColor: '$primary-container' }}>
                        <PersonIcon size={96} fill="$on-primary-container" />
                      </IconWrapper>
                    )}

                    <IconButton
                      onClick={() => document.getElementById(fileInputId)?.click()}
                      variant="filled"
                      css={{ position: 'absolute', bottom: 0, right: 0 }}
                      disabled={!checkPermissionRole('user.picture')}
                      data-testid="user.picture"
                    >
                      <EditIcon />
                      <FormFileInput
                        id={fileInputId}
                        name="picture"
                        accept=".svg,.png,.svg+xml,.jpeg,.jpg"
                        control={control}
                        onBase64Change={(file) => setSelectedImage(file)}
                        onError={(error) => setFileSizeError(error)}
                      />
                    </IconButton>
                  </Div>

                  <Typography variant="labelLarge" css={{ color: '$on-surface-variant' }}>
                    {i18n().modules.hub.profiles.pages.form.imageSupporting}
                  </Typography>
                  {fileSizeError && (
                    <Typography variant="labelLarge" css={{ color: '$critical' }}>
                      {fileSizeError}
                    </Typography>
                  )}
                </Div>
                <FormContainer
                  css={{ display: 'flex', flexDirection: 'column', gap: '$lg', width: '100%' }}
                  formHandleSubmit={handleSubmit}
                  onSubmit={onSubmit}
                >
                  <Grid spacing="$lg">
                    <FormTextField
                      control={control}
                      name="name"
                      label={i18n().modules.hub.profiles.pages.form.name}
                      required
                      variant="outlined"
                      errorText={errors.name?.message}
                      gridProps={{ xl: 12 }}
                      disabled={!checkPermissionRole('user.name')}
                    />
                    <Grid xl={12}>
                      <TextField
                        label={i18n().modules.hub.profiles.pages.form.organization}
                        inputProps={{ value: profile.organization.name }}
                        variant="outlined"
                        disabled
                        data-testid="organization"
                      />
                    </Grid>
                    <FormTextField
                      control={control}
                      name="phone"
                      label={i18n().modules.hub.profiles.pages.form.phone}
                      variant="outlined"
                      errorText={errors.phone?.message}
                      inputProps={{ mask: cellMask }}
                      gridProps={{ xl: 6, md: 12 }}
                      disabled={!checkPermissionRole('user.phone')}
                    />
                    <FormTextField
                      control={control}
                      name="cpf"
                      label={i18n().modules.hub.profiles.pages.form.cpf(profile.role.code)}
                      errorText={errors.cpf?.message}
                      variant="outlined"
                      inputProps={{ mask: cpfMask }}
                      required={profile.role.code === 'professor'}
                      gridProps={{ xl: 6, md: 12 }}
                      disabled={!checkPermissionRole('user.document')}
                    />
                    <FormTextField
                      control={control}
                      name="birthday"
                      label={i18n().modules.hub.profiles.pages.form.birthday}
                      required
                      variant="outlined"
                      errorText={errors.birthday?.message}
                      inputProps={{
                        mask: defaultDateMask
                      }}
                      gridProps={{ xl: 6, md: 12 }}
                      disabled={!checkPermissionRole('user.birthday')}
                    />
                    <FormSelect
                      name="gender"
                      optionKeyField="key"
                      optionTitleField="value"
                      label={i18n().modules.hub.profiles.pages.form.gender}
                      required
                      variant="outlined"
                      control={control}
                      onChange={(model) => {
                        setValue('gender', model?.key ?? '')
                      }}
                      errorText={errors.gender?.message}
                      options={genderOptions}
                      gridProps={{ xl: 6, md: 12 }}
                      disabled={!checkPermissionRole('user.gender')}
                    />
                    {!['aluno', 'professor'].includes(profile.role.code) && (
                      <FormCheckbox
                        name="isDpoUser"
                        register={register}
                        label={i18n().modules.hub.profiles.pages.form.DPO}
                        hasError={!!errors.isDpoUser?.message}
                        gridProps={{ xl: 12 }}
                        inputProps={{
                          'data-testid': 'Checkbox-Input-dpoUser'
                        }}
                      />
                    )}
                    <Grid xl={12} css={{ display: 'flex', justifyContent: 'space-between' }}>
                      <Button
                        onClick={() => navigate(-1)}
                        variant="outlined"
                        LeadingIcon={<CancelIcon size={18} />}
                        data-testid="cancel"
                        disabled={updateProfile.isPending}
                      >
                        {i18n().modules.hub.profiles.pages.form.buttons.cancel}
                      </Button>

                      <Button
                        type="submit"
                        variant="filled"
                        isLoading={updateProfile.isPending}
                        LeadingIcon={<CheckCircleIcon size={18} />}
                        data-testid="submit"
                      >
                        {i18n().modules.hub.profiles.pages.form.buttons.save}
                      </Button>
                    </Grid>
                  </Grid>
                </FormContainer>
              </BaseCard>
            </Grid>
          )}
        </Div>
        {breakpoint === Breakpoint.xs && <Footer />}
      </Div>
    </Main>
  )
}
