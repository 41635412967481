import { BaseCardButton } from '@positivote/design-system/components/BaseCardButton'
import { Div } from '@positivote/design-system/components/Div'
import { IconWrapper } from '@positivote/design-system/components/IconWrapper'
import { Main } from '@positivote/design-system/components/Main'
import { Typography } from '@positivote/design-system/components/Typography'
import { useTheme } from '@positivote/design-system/hooks'
import { DashboardIcon } from '@positivote/design-system/icons/Dashboard'
import { LinkIcon } from '@positivote/design-system/icons/Link'
import { PersonIcon } from '@positivote/design-system/icons/Person'
import { SecurityIcon } from '@positivote/design-system/icons/Security'
import { StairsIcon } from '@positivote/design-system/icons/Stairs'
import { Breakpoint } from '@positivote/design-system/theme'
import { useNavigate } from 'react-router-dom'

import { AppBar } from '@/common/components/AppBar'
import { LargerScreensOnly } from '@/common/components/LargerScreensOnly'
import { changePageTitle } from '@/common/helpers'
import { i18n } from '@/common/i18n'
import { useAuth } from '@/modules/hub/auth/contexts'

export function DataManagementMenu(): JSX.Element {
  changePageTitle(i18n().modules.hub.dataManagement.pages.menu.pageTitle)

  const { breakpoint } = useTheme()
  const navigate = useNavigate()

  const { profile, permissions } = useAuth()

  function handleOpen(target: string): void {
    navigate(target)
  }

  const allButtonOptions = [
    {
      key: 'my-users',
      icon: <PersonIcon fill="$on-primary-container" />,
      label: i18n().modules.hub.dataManagement.pages.menu.option.myUsers,
      description: i18n().modules.hub.dataManagement.pages.menu.option.myUsersDescription,
      route: 'my-users',
      roles: ['administrador', 'instituicao'],
      permissions: []
    },
    {
      key: 'school',
      icon: <StairsIcon fill="$on-primary-container" />,
      label: i18n().modules.hub.dataManagement.pages.menu.option.educationLevelsAndClasses,
      description:
        i18n().modules.hub.dataManagement.pages.menu.option.educationLevelsAndClassesDescription,
      route: 'levels-and-classes',
      roles: ['administrador', 'instituicao'],
      permissions: ['onboarding']
    },
    {
      key: 'access-configuration',
      icon: <LinkIcon fill="$on-primary-container" />,
      label: i18n().modules.hub.dataManagement.pages.menu.option.accessConfiguration,
      description:
        i18n().modules.hub.dataManagement.pages.menu.option.accessConfigurationDescription,
      route: 'access-configuration',
      roles: ['administrador', 'instituicao'],
      permissions: ['app-access']
    },
    {
      key: 'security-configuration',
      icon: <SecurityIcon fill="$on-primary-container" />,
      label: i18n().modules.hub.dataManagement.pages.menu.option.securityConfiguration,
      description:
        i18n().modules.hub.dataManagement.pages.menu.option.securityConfigurationDescription,
      route: 'security-configuration',
      roles: ['administrador', 'instituicao'],
      permissions: []
    },
    {
      key: 'onboarding',
      icon: <DashboardIcon fill="$on-primary-container" />,
      label: i18n().modules.hub.dataManagement.pages.menu.option.onboarding,
      description: i18n().modules.hub.dataManagement.pages.menu.option.onboardingDescription,
      route: 'onboarding',
      roles: [],
      permissions: ['onboarding']
    }
  ]

  const filteredOptions = allButtonOptions.filter((buttonOption) => {
    return (
      !!profile &&
      !!permissions &&
      (buttonOption.roles.includes(profile.role.code) ||
        permissions.some((permission) => buttonOption.permissions.includes(permission.key)))
    )
  })

  return (
    <Main css={{ display: 'flex', flexDirection: 'column', flex: 1, overflowX: 'hidden' }}>
      <AppBar
        title={i18n().modules.hub.dataManagement.pages.menu.appBar.title}
        goBackFunction={() => navigate(-1)}
      />
      {breakpoint <= Breakpoint.md ? (
        <LargerScreensOnly />
      ) : (
        <Div
          css={{
            display: 'flex',
            flexDirection: 'column',
            padding: '$lg',
            gap: '$md',
            overflowY: 'auto',
            '@sm': { padding: '$md' }
          }}
        >
          {filteredOptions.map((buttonOption) => (
            <BaseCardButton
              key={buttonOption.key}
              data-testid={`BaseCardButton-Container-DataManagementMenu-${buttonOption.key}`}
              onClick={() => handleOpen(buttonOption.route)}
              css={{
                width: '80%',
                '& .BaseCardButton-StateLayer': {
                  padding: '$md',
                  gap: '$md',
                  justifyContent: 'flex-start'
                }
              }}
            >
              <IconWrapper css={{ backgroundColor: '$primary-container' }}>
                {buttonOption.icon}
              </IconWrapper>
              <Div
                css={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start'
                }}
              >
                <Typography variant="bodyLarge" css={{ textAlign: 'left', color: '$on-surface' }}>
                  {buttonOption.label}
                </Typography>
                <Typography
                  variant="bodyMedium"
                  css={{ textAlign: 'left', color: '$on-surface-variant' }}
                >
                  {buttonOption.description}
                </Typography>
              </Div>
            </BaseCardButton>
          ))}
        </Div>
      )}
    </Main>
  )
}
