import { Navigate, Route, Routes } from 'react-router-dom'

import { MyUsersList } from './pages/List'

export function UsersRoutes(): JSX.Element {
  return (
    <Routes>
      <Route path="/" element={<MyUsersList />} />

      <Route path="*" element={<Navigate replace to="/not-found" />} />
    </Routes>
  )
}
