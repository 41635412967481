import { i18n } from '@/common/i18n'
import { listHubApiResultSanitizer } from '@/common/sanitizers'
import { hubApiService } from '@/common/services'
import {
  fromAccountProfileApiSanitizer,
  updateProfileApiParamsSanitizer
} from '@/modules/hub/profiles/sanitizers'

import {
  ListAccountProfileApiParams,
  ListAccountProfileApiResult,
  ListProfileRoleApiResult,
  ListProfileRoleServiceResult,
  ListProfileServiceParams,
  ListProfileServiceResult,
  UpdateProfileApiParams,
  UpdateProfileConsentApiParams,
  UpdateProfileConsentServiceParams,
  UpdateProfileServiceParams
} from './contracts'

export async function updateProfileService(params: UpdateProfileServiceParams): Promise<void> {
  await hubApiService<UpdateProfileApiParams, { id: string }>({
    resource: i18n().modules.hub.profiles.resources.update,
    method: 'put',
    url: `/users/v1/profiles/${params.id}`,
    body: updateProfileApiParamsSanitizer(params)
  })
}

export async function listProfileService(
  params: ListProfileServiceParams
): Promise<ListProfileServiceResult> {
  const {
    data: { data, account, ...result }
  } = await hubApiService<ListAccountProfileApiParams, ListAccountProfileApiResult>({
    resource: i18n().modules.hub.profiles.resources.list,
    method: 'get',
    url: '/users/v1/account/profiles',
    params: {
      org_id: params.orgId,
      app_id: params.appId,
      search: params.search,
      by_account: params.byAccount,
      role_code: params.roleCode,
      page: params.page,
      size: params.perPage
    }
  })

  return {
    ...listHubApiResultSanitizer({ ...result, data: fromAccountProfileApiSanitizer(data) }),
    account
  }
}

export async function listMyProfileService(
  params: ListProfileServiceParams
): Promise<ListProfileServiceResult> {
  const {
    data: { data, account, ...result }
  } = await hubApiService<ListAccountProfileApiParams, ListAccountProfileApiResult>({
    resource: i18n().modules.hub.profiles.resources.list,
    method: 'get',
    url: '/users/v1/users',
    params: {
      org_id: params.orgId,
      app_id: params.appId,
      search: params.search,
      role_code: params.roleCode,
      page: params.page,
      size: params.perPage
    }
  })

  return {
    ...listHubApiResultSanitizer({ ...result, data: fromAccountProfileApiSanitizer(data) }),
    account
  }
}

export async function listProfileRolesService(): Promise<ListProfileRoleServiceResult> {
  const { data } = await hubApiService<undefined, ListProfileRoleApiResult>({
    resource: i18n().modules.hub.profiles.resources.listRoles,
    method: 'get',
    url: '/users/v1/roles'
  })

  return listHubApiResultSanitizer({ data: data.data })
}

export async function updateProfileConsentService(
  params: UpdateProfileConsentServiceParams
): Promise<void> {
  await hubApiService<UpdateProfileConsentApiParams, undefined>({
    resource: i18n().modules.hub.profiles.resources.updateConsent,
    method: 'put',
    url: `/users/v1/profiles/consent/${params.profileId}`,
    body: {
      consent: params.consent
    }
  })
}
