import { yupResolver } from '@hookform/resolvers/yup'
import { Button } from '@positivote/design-system/components/Button'
import { Div } from '@positivote/design-system/components/Div'
import { IconWrapper } from '@positivote/design-system/components/IconWrapper'
import { FormContainer } from '@positivote/design-system/components-form/Container'
import { FormTextField } from '@positivote/design-system/components-form/TextField'
import { PersonIcon } from '@positivote/design-system/icons/Person'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

import { changePageTitle } from '@/common/helpers'
import { i18n } from '@/common/i18n'
import { useAuth } from '@/modules/hub/auth/contexts'
import { UsernameLoginForm } from '@/modules/hub/auth/contracts/forms'
import { usernameLoginFormSanitizer } from '@/modules/hub/auth/sanitizers'
import { usernameLoginValidationSchema } from '@/modules/hub/auth/validations'

interface UsernameFormLoginProps {
  scrollIntoView: () => void
  setUsername: (value: string) => void
}

export function UsernameFormLogin({
  scrollIntoView,
  setUsername
}: UsernameFormLoginProps): JSX.Element {
  changePageTitle(i18n().modules.hub.auth.pages.login.pageTitle)

  const { isLoading, isLoadingGoogle, isLoadingMicrosoft } = useAuth()
  const navigate = useNavigate()
  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm<UsernameLoginForm>({
    mode: 'onSubmit',
    resolver: async (values, ...args) =>
      yupResolver(usernameLoginValidationSchema)(usernameLoginFormSanitizer(values), ...args)
  })
  const hasAnyError = !!Object.keys(errors).length

  function onSubmit(model: UsernameLoginForm): void {
    setUsername(model.username)
  }

  return (
    <FormContainer
      formHandleSubmit={handleSubmit}
      onSubmit={onSubmit}
      isLoading={isLoading || isLoadingGoogle || isLoadingMicrosoft}
      css={{ display: 'flex', flexDirection: 'column', width: '100%' }}
    >
      <Div css={{ display: 'flex' }}>
        <IconWrapper
          size="$2xl"
          css={{
            backgroundColor: '$primary-container',
            marginTop: '$2xs',
            marginRight: '$md',
            '@xs': { display: 'none' }
          }}
        >
          <PersonIcon fill="$on-primary-container" />
        </IconWrapper>
        <FormTextField
          control={control}
          name="username"
          label={i18n().modules.hub.auth.pages.login.inputs.username}
          variant="outlined"
          errorText={errors.username?.message}
          required
          inputProps={{
            // TODO: put these props by default in text fields input
            autoComplete: 'new-password',
            autoCapitalize: 'off',
            autoCorrect: 'off',
            spellCheck: false,
            inputMode: 'email',
            onFocus: scrollIntoView
          }}
          disabled={isLoading || isLoadingGoogle || isLoadingMicrosoft}
        />
      </Div>
      <Div css={{ display: 'flex', marginTop: '$lg', '@xs': { flexDirection: 'column-reverse' } }}>
        <IconWrapper size="$2xl" css={{ marginRight: '$md', '@xs': { display: 'none' } }} />
        <Button
          data-testid="forgotPassword"
          variant="outlined"
          css={{
            flex: 1,
            marginRight: '$md',
            '@xs': { marginRight: '$none', marginTop: '$2xs' }
          }}
          onClick={() => navigate('/recover-password')}
          disabled={isLoading || isLoadingGoogle || isLoadingMicrosoft}
        >
          {i18n().modules.hub.auth.pages.login.buttons.forgotPassword}
        </Button>
        <Button
          type="submit"
          variant="filled"
          css={{ flex: 1 }}
          disabled={hasAnyError || isLoadingGoogle || isLoadingMicrosoft}
          data-testid="submitButton"
        >
          {i18n().modules.hub.auth.pages.login.buttons.next}
        </Button>
      </Div>
    </FormContainer>
  )
}
