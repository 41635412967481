import { i18n } from '@/common/i18n'
import { listHubApiResultSanitizer } from '@/common/sanitizers'
import { hubApiService } from '@/common/services'

import {
  ListClassLevelApiResult,
  ListClassLevelServiceResult,
  ListSchoolClassApiParams,
  ListSchoolClassApiResult,
  ListSchoolClassServiceParams,
  ListSchoolClassServiceResult,
  ListSchoolClassStudentApiParams,
  ListSchoolClassStudentApiResult,
  ListSchoolClassStudentServiceParams,
  ListSchoolClassStudentServiceResult
} from './contracts'
import { schoolClassFormatSanitizer } from './sanitizers'

export async function listClassLevelService(): Promise<ListClassLevelServiceResult> {
  const {
    data: { data, ...result }
  } = await hubApiService<null, ListClassLevelApiResult>({
    resource: i18n().modules.hub.schoolClasses.resources.listClassLevel,
    method: 'get',
    url: '/classes/v1/levels'
  })
  return {
    ...listHubApiResultSanitizer({
      ...result,
      data
    })
  }
}

export async function listSchoolClassService({
  levelId,
  search,
  page,
  perPage
}: ListSchoolClassServiceParams): Promise<ListSchoolClassServiceResult> {
  const {
    data: { data, ...result }
  } = await hubApiService<ListSchoolClassApiParams, ListSchoolClassApiResult>({
    resource: i18n().modules.hub.schoolClasses.resources.listSchoolClass,
    method: 'get',
    url: '/classes/v1/classes',
    params: {
      level_id: levelId,
      size: perPage,
      search,
      page
    }
  })

  return {
    ...listHubApiResultSanitizer({
      ...result,
      total_pages: result.pages,
      data: schoolClassFormatSanitizer(data)
    })
  }
}

export async function listSchoolClassStudentsService({
  classId,
  perPage,
  page
}: ListSchoolClassStudentServiceParams): Promise<ListSchoolClassStudentServiceResult> {
  const { data } = await hubApiService<
    ListSchoolClassStudentApiParams,
    ListSchoolClassStudentApiResult
  >({
    resource: i18n().modules.hub.schoolClasses.resources.listSchoolClass,
    method: 'get',
    url: `/classes/v1/classes/${classId}/students`,
    params: {
      size: perPage,
      page
    }
  })

  return {
    schoolClass: data.class,
    ...listHubApiResultSanitizer({
      ...data,
      total: data.total_count,
      total_pages: data.pages,
      content_range: data.per_page,
      data: data.students
    })
  }
}
