import { BaseButton } from '@positivote/design-system/components/BaseButton'
import { Div } from '@positivote/design-system/components/Div'
import { IconButton } from '@positivote/design-system/components/IconButton'
import { Menu } from '@positivote/design-system/components/Menu'
import { SettingsIcon } from '@positivote/design-system/icons/Settings'
import { SettingsApplicationsIcon } from '@positivote/design-system/icons/SettingsApplications'
import { CSS } from '@positivote/design-system/theme'
import { useMemo, useState } from 'react'
import { isElectron } from 'react-device-detect'

import { TextDialog } from '@/common/components/TextDialog'
import { i18n } from '@/common/i18n'
import { useHandleAccessApplication } from '@/modules/hub/applications/hooks'
import { useAuth } from '@/modules/hub/auth/contexts'
import { ConfigureAccessDialog } from '@/modules/hub/hubot/components'
import { UserFlow } from '@/modules/hub/hubot/contracts'
import { useShowUserFlow } from '@/modules/hub/hubot/hooks'

import { AppBaseCardProps } from './styles'

interface SelectOption {
  key: 'configureAccess'
  icon: JSX.Element
  title: string
}

export function AppBaseCard({
  application,
  setApplicationIdLoading,
  enableOptions,
  canClick,
  css,
  children,
  wrapperProps,
  ...restProps
}: Omit<AppBaseCardProps, 'applicationIdLoading'>): JSX.Element {
  const filteredOptions = useMemo<SelectOption[]>(() => {
    const options: SelectOption[] = []
    if (
      enableOptions.includes('configureAccess') &&
      application.loginType === 'AUTOLOGIN' &&
      isElectron
    ) {
      options.push({
        key: 'configureAccess',
        icon: <SettingsApplicationsIcon />,
        title: i18n().modules.hub.applications.components.appBaseCard.configureAccess
      })
    }
    return options
  }, [application.loginType, enableOptions])
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const [isConfigureAccessOpen, setIsConfigureAccessOpen] = useState(false)
  const [userFlow, setUserFlow] = useState<UserFlow | undefined>(undefined)

  const { profile } = useAuth()
  const showUserFlow = useShowUserFlow()
  const { dialogData, handleAccess } = useHandleAccessApplication({
    onStartLoad: setApplicationIdLoading,
    onEndLoad: () => setApplicationIdLoading(null),
    openConfigureAccessDialog: (params) => {
      setIsConfigureAccessOpen(true)
      setUserFlow(params)
    }
  })

  function handleCloseConfigureAccessOpen(): void {
    setIsConfigureAccessOpen(false)
    setUserFlow(undefined)
  }

  function handleSelectOption(option: SelectOption | null): void {
    if (option) {
      ;({
        configureAccess: (): void => {
          if (application.code && profile?.id) {
            setIsConfigureAccessOpen(true)
            showUserFlow.mutate({
              model: { applicationId: application.code, profileId: profile.id },
              onSuccess: setUserFlow,
              onError: handleCloseConfigureAccessOpen
            })
          }
        }
      })[option.key]()
    }
  }

  return (
    <Div
      className="AppBaseCard-Wrapper"
      css={{ position: 'relative', ...wrapperProps?.css }}
      {...wrapperProps}
    >
      <BaseButton
        className="AppBaseCard"
        onClick={() => {
          if (canClick) {
            handleAccess(application)
          }
        }}
        css={{
          backgroundColor: '$surface-2',
          borderRadius: '$xl',
          ...css,
          '& .Button-StateLayer': {
            flexDirection: 'column',
            padding: '$lg',
            gap: '$2xs',
            ...(css?.['& .Button-StateLayer'] as CSS)
          }
        }}
        {...restProps}
      >
        {children}
      </BaseButton>

      <ConfigureAccessDialog
        application={application}
        userFlow={userFlow}
        isOpen={isConfigureAccessOpen}
        onClose={handleCloseConfigureAccessOpen}
        isLoading={showUserFlow.isPending}
      />
      <TextDialog {...dialogData} />
      <Menu
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        placement="bottom-end"
        options={filteredOptions}
        optionKeyField="key"
        optionTitleField="title"
        leadingElementField="icon"
        onSelectOption={handleSelectOption}
        css={{ width: 'max-content', maxWidth: '248px' }}
      />
      {!!filteredOptions.length && (
        <IconButton
          variant="standard"
          css={{ position: 'absolute', top: '12px', right: '12px' }}
          onClick={(event) => {
            event.stopPropagation()
            setAnchorEl(event.currentTarget)
          }}
        >
          <SettingsIcon />
        </IconButton>
      )}
    </Div>
  )
}
