import { yupResolver } from '@hookform/resolvers/yup'
import { Chip } from '@positivote/design-system/components/Chip'
import { Div } from '@positivote/design-system/components/Div'
import { Grid } from '@positivote/design-system/components/Grid'
import { Loader } from '@positivote/design-system/components/Loader'
import { Typography } from '@positivote/design-system/components/Typography'
import { FormCheckbox } from '@positivote/design-system/components-form/Checkbox'
import { FormDatePicker } from '@positivote/design-system/components-form/DatePicker'
import { FormSelect } from '@positivote/design-system/components-form/Select'
import { FormTextField } from '@positivote/design-system/components-form/TextField'
import { ArrowBackIcon } from '@positivote/design-system/icons/ArrowBack'
import { CloseIcon } from '@positivote/design-system/icons/Close'
import { forwardRef, useCallback, useEffect, useImperativeHandle, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'

import { TextDialog } from '@/common/components/TextDialog'
import { debounceEvent } from '@/common/helpers'
import { i18n } from '@/common/i18n'
import { useAuth } from '@/modules/hub/auth/contexts'
import { OrganizationKind } from '@/modules/hub/organizations/contracts'
import { SchoolYearStepForm, SchoolYearStepperState } from '@/modules/hub/school-year/contracts'
import { useListSchoolYear } from '@/modules/hub/school-year/hooks'
import { schoolYearDataValidationSchema } from '@/modules/hub/school-year/validations'

interface SchoolYearDataStepProps {
  stepperState: SchoolYearStepperState
  setStepState: (stepperState: Partial<SchoolYearStepperState['schoolYearData']>) => void
  isLoading: boolean
}

export const SchoolYearDataStep = forwardRef(function SchoolYearDataStep(
  { stepperState, setStepState, isLoading }: SchoolYearDataStepProps,
  ref
) {
  const [name, setName] = useState('')
  const { profile } = useAuth()
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false)

  const navigate = useNavigate()

  const params = useParams()
  const listSchoolYear = useListSchoolYear({
    model: {
      withClassRooms: true,
      institutionId: String(stepperState.selectedSchool.form?.idSchool ?? profile?.organizationId)
    }
  })

  const { control, formState, watch, register, setError, clearErrors, handleSubmit, reset } =
    useForm<SchoolYearStepForm>({
      mode: 'onSubmit',
      resolver: yupResolver(schoolYearDataValidationSchema)
    })

  const hasCopy = watch('isCopy')
  const hasError = !!Object.keys(formState.errors).length
  const minSearchNameLength = 3

  const isSchool = profile?.organization.kindId === OrganizationKind.SCHOOL

  const listSchoolYearSearchName = useListSchoolYear({
    model: {
      institutionId: String(stepperState.selectedSchool.form?.idSchool ?? profile?.organizationId),
      search: name
    },
    queryOptions: {
      enabled: name.length >= minSearchNameLength,
      staleTime: 0
    },
    onSuccess: (data) => {
      if (data.registers.find((schoolYear) => schoolYear.title === name)) {
        setError('name', {
          message:
            i18n().modules.hub.schoolYear.pages.form.stepper.schoolYearData.validators
              .nameSchoolYearExist
        })
      } else {
        clearErrors('name')
      }
    }
  })

  function handleChangeSearchText(event: React.ChangeEvent<HTMLInputElement>): void {
    const searchBy = event.target.value || undefined
    debounceEvent(() => {
      setName(String(searchBy))
    })()
  }

  const validateDataForm = useCallback(async (): Promise<SchoolYearStepForm | null> => {
    return new Promise((resolve) => {
      void handleSubmit(
        (data) => resolve(data),
        () => resolve(null)
      )()
    })
  }, [handleSubmit])

  const handleCancel = useCallback((): void => {
    const isDirty =
      watch('name') ||
      watch('finalDate') ||
      watch('initialDate') ||
      watch('schoolYear') ||
      watch('teacher')

    if (!isDirty) {
      navigate(-1)
    } else {
      setIsConfirmDialogOpen(true)
    }
  }, [navigate, watch])

  useImperativeHandle(ref, () => ({ validateDataForm, handleCancel }), [
    validateDataForm,
    handleCancel
  ])

  useEffect(() => {
    if (stepperState.schoolYearData.form) {
      reset({
        name: stepperState.schoolYearData.form.name,
        initialDate: stepperState.schoolYearData.form.initialDate,
        finalDate: stepperState.schoolYearData.form.finalDate,
        isCopy: stepperState.schoolYearData.form.isCopy,
        schoolYear: stepperState.schoolYearData.form.schoolYear,
        teacher: stepperState.schoolYearData.form.teacher
      })
    }
  }, [reset, stepperState.schoolYearData.form])

  useEffect(() => {
    setStepState({
      hasError,
      canGoNext: !hasError
    })
    // DOCS: only render when hasError
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasError])

  useEffect(() => {
    if (!hasCopy) {
      clearErrors('schoolYear')
      clearErrors('teacher')
    }
  }, [clearErrors, hasCopy])

  return (
    <Div
      css={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '$lg',
        minHeight: listSchoolYear.isLoading && !params.id ? 242 : 180
      }}
    >
      <TextDialog
        isOpen={isConfirmDialogOpen}
        title={{ label: i18n().modules.hub.schoolYear.pages.form.textDialog.title }}
        contentTexts={[i18n().modules.hub.schoolYear.pages.form.textDialog.contentText]}
        onCancel={() => setIsConfirmDialogOpen(false)}
        refuseAction={{
          icon: <ArrowBackIcon size={18} />,
          label: i18n().modules.hub.schoolYear.pages.form.textDialog.refuseAction,
          handle: () => setIsConfirmDialogOpen(false)
        }}
        acceptAction={{
          icon: <CloseIcon size={18} />,
          label: i18n().modules.hub.schoolYear.pages.form.textDialog.acceptAction,
          handle: () => navigate(-1)
        }}
        css={{ maxWidth: 600 }}
      />
      {listSchoolYear.isLoading || isLoading ? (
        <Div
          css={{
            display: 'flex',
            flexDirection: 'column',
            padding: '$lg',
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <Loader size={80} />
        </Div>
      ) : (
        <>
          {!isSchool && (
            <Chip color="neutral" label={stepperState.selectedSchool.form?.schoolName ?? ''} />
          )}
          <Typography variant="bodyMedium" css={{ color: '$on-surface-variant' }}>
            {i18n().modules.hub.schoolYear.pages.form.stepper.schoolYearData.title}
          </Typography>
          <Grid spacing="$lg">
            <FormTextField
              required
              label={
                i18n().modules.hub.schoolYear.pages.form.stepper.schoolYearData.titleSchoolYear
              }
              variant="outlined"
              name="name"
              control={control}
              errorText={formState.errors.name?.message}
              inputProps={{
                onChange: (event: React.ChangeEvent<HTMLInputElement>) =>
                  handleChangeSearchText(event)
              }}
              trailingIcon={
                listSchoolYearSearchName.isLoading
                  ? {
                      icon: (props) => <Loader {...props} fill="$primary" />,
                      changeIconOnError: false
                    }
                  : undefined
              }
            />
            <Grid spacing="$lg">
              <FormDatePicker
                required
                label={i18n().modules.hub.schoolYear.pages.form.stepper.schoolYearData.startDate}
                variant="outlined"
                name="initialDate"
                control={control}
                gridProps={{ xl: 6 }}
                errorText={formState.errors.initialDate?.message}
                placement="auto"
              />
              <FormDatePicker
                label={i18n().modules.hub.schoolYear.pages.form.stepper.schoolYearData.endDate}
                required
                variant="outlined"
                name="finalDate"
                control={control}
                gridProps={{ xl: 6 }}
                errorText={formState.errors.finalDate?.message}
                placement="auto"
              />
            </Grid>
            {!params.id && !!listSchoolYear.data?.registers.length && (
              <FormCheckbox
                disabled={!listSchoolYear.data.registers.length}
                register={register}
                name="isCopy"
                label={i18n().modules.hub.schoolYear.pages.form.stepper.schoolYearData.copyClass}
              />
            )}

            {watch('isCopy') && (
              <Grid spacing="$lg">
                <FormSelect
                  required
                  name="schoolYear"
                  label={
                    i18n().modules.hub.schoolYear.pages.form.stepper.schoolYearData.selectSchoolYear
                  }
                  control={control}
                  variant="outlined"
                  optionKeyField="id"
                  optionTitleField="title"
                  gridProps={{ xl: 6 }}
                  options={listSchoolYear.data?.registers ?? []}
                  errorText={formState.errors.schoolYear?.message}
                />
                <FormSelect
                  variant="outlined"
                  label={
                    i18n().modules.hub.schoolYear.pages.form.stepper.schoolYearData.selectLinks
                  }
                  control={control}
                  name="teacher"
                  optionKeyField="key"
                  optionTitleField="value"
                  gridProps={{ xl: 6 }}
                  errorText={formState.errors.teacher?.message}
                  options={[
                    {
                      value:
                        i18n().modules.hub.schoolYear.pages.form.stepper.schoolYearData.withTeacher,
                      key: true
                    },
                    {
                      value:
                        i18n().modules.hub.schoolYear.pages.form.stepper.schoolYearData.noTeacher,
                      key: false
                    }
                  ]}
                />
              </Grid>
            )}
          </Grid>
        </>
      )}
    </Div>
  )
})
