import { UseMutationResult, useMutation } from '@tanstack/react-query'

import { ApplicationException } from '@/common/exceptions'
import { useErrorHandler } from '@/common/hooks'

import { MakeLtiLaunchHookParams, MakeLtiLaunchHookResult } from './contracts/hooks'
import { makeLtiLaunchService } from './services'

export const ltiHookKey = 'lti'

export function useMakeLtiLaunch(): UseMutationResult<
  MakeLtiLaunchHookResult,
  ApplicationException,
  MakeLtiLaunchHookParams
> {
  const { handleError } = useErrorHandler()

  return useMutation({
    mutationKey: [ltiHookKey, 'makeLaunch'],
    mutationFn: async ({
      model,
      openNewTab = true,
      onSuccess,
      onError
    }: MakeLtiLaunchHookParams) => {
      try {
        const ltiLaunchResult = await makeLtiLaunchService(model)
        onSuccess?.(ltiLaunchResult)

        const hasParams = !!ltiLaunchResult.params && !!Object.keys(ltiLaunchResult.params).length
        if (!hasParams || ltiLaunchResult.method.toLocaleLowerCase() === 'get') {
          const queryParams = hasParams
            ? `?${new URLSearchParams(ltiLaunchResult.params).toString()}`
            : ''
          const url = `${ltiLaunchResult.action}${queryParams}`
          if (openNewTab) {
            window.open(url, '_blank')?.focus()
          } else {
            window.location.assign(url)
          }
        } else {
          const form = document.createElement('form')
          form.action = ltiLaunchResult.action
          form.method = ltiLaunchResult.method
          form.target = openNewTab ? '_blank' : '_self'
          form.innerHTML = Object.entries(ltiLaunchResult.params ?? {})
            .map(([name, value]) => `<input type="hidden" name="${name}" value="${value}" />`)
            .join('\n')
          document.body.appendChild(form)
          /**
           * DOCS: SetTimeout using a 0 millisecond delay in this context allows you to ensure that the form is only
           * submitted after it is added to the body of the document, in order to execute eventLoop's form submit nextTick.
           */
          setTimeout(() => form.submit(), 0)
        }

        return ltiLaunchResult
      } catch (error) {
        const parsedError = error as ApplicationException
        onError?.({ error: parsedError })
        handleError({ error: parsedError })
        throw parsedError
      }
    }
  })
}
