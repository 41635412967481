import { BaseCardButton } from '@positivote/design-system/components/BaseCardButton'
import { Div } from '@positivote/design-system/components/Div'
import { IconWrapper } from '@positivote/design-system/components/IconWrapper'
import { Main } from '@positivote/design-system/components/Main'
import { Typography } from '@positivote/design-system/components/Typography'
import { useTheme } from '@positivote/design-system/hooks'
import { TodayIcon } from '@positivote/design-system/icons/Today'
import { Breakpoint } from '@positivote/design-system/theme'
import { useNavigate } from 'react-router-dom'

import { AppBar } from '@/common/components/AppBar'
import { LargerScreensOnly } from '@/common/components/LargerScreensOnly'
import { i18n } from '@/common/i18n'

export function LevelsAndClasses(): JSX.Element {
  const { breakpoint } = useTheme()
  const navigate = useNavigate()

  const allButtonOptions = [
    {
      key: 'school-year',
      icon: <TodayIcon fill="$on-primary-container" />,
      label: i18n().modules.hub.dataManagement.pages.schoolMenu.option.schoolYear,
      description: i18n().modules.hub.dataManagement.pages.schoolMenu.option.schoolYearDescription,
      route: 'school-year',
      roles: ['administrador', 'instituicao'],
      permissions: []
    }
  ]

  function handleOpen(target: string): void {
    navigate(target)
  }

  return (
    <Main css={{ display: 'flex', flexDirection: 'column', flex: 1, overflowX: 'hidden' }}>
      <AppBar
        title={i18n().modules.hub.dataManagement.pages.menu.appBar.title}
        goBackFunction={() => navigate(-1)}
        breadcrumbItems={[
          {
            label: i18n().modules.hub.dataManagement.pages.schoolMenu.appBar.breadcrumbs.overview,
            onClick: () => {
              navigate(-1)
            }
          },
          { label: i18n().modules.hub.dataManagement.pages.schoolMenu.appBar.breadcrumbs.title }
        ]}
      />
      {breakpoint <= Breakpoint.md ? (
        <LargerScreensOnly />
      ) : (
        <Div
          css={{
            display: 'flex',
            flexDirection: 'column',
            padding: '$lg',
            gap: '$md',
            overflowY: 'auto',
            '@sm': { padding: '$md' }
          }}
        >
          {allButtonOptions.map((buttonOption) => (
            <BaseCardButton
              key={buttonOption.key}
              data-testid={`BaseCardButton-Container-DataManagementMenu-${buttonOption.key}`}
              onClick={() => handleOpen(buttonOption.route)}
              css={{
                width: '80%',
                '& .BaseCardButton-StateLayer': {
                  padding: '$md',
                  gap: '$md',
                  justifyContent: 'flex-start'
                }
              }}
            >
              <IconWrapper css={{ backgroundColor: '$primary-container' }}>
                {buttonOption.icon}
              </IconWrapper>
              <Div
                css={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start'
                }}
              >
                <Typography variant="bodyLarge" css={{ textAlign: 'left', color: '$on-surface' }}>
                  {buttonOption.label}
                </Typography>
                <Typography
                  variant="bodyMedium"
                  css={{ textAlign: 'left', color: '$on-surface-variant' }}
                >
                  {buttonOption.description}
                </Typography>
              </Div>
            </BaseCardButton>
          ))}
        </Div>
      )}
    </Main>
  )
}
